import { useEffect, Dispatch, SetStateAction } from 'react';
import { Row, Col, Typography, Button, Switch, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import DateSelector from '../DateSelector/DateSelector';
import Input from '../Input/Input';
import Select from '../Select/Select';
import FormGroup from '../FormGroup/FormGroup';
import styles from './SurveyFilters.module.scss';
import surveysService from '../../services/surveys.service';
import { useSurveysContext } from '../../context/Surveys.context';
import { DATE_FORMAT } from '../../constants/formatting';
import debounce from '../../utils/form-utils';
import { SurveyFilter } from '../../types/Filters';
import { SurveysPayload } from '../../types/Surveys';

interface ConditionalTootipProps {
  display: boolean;
  title: string;
  children: React.ReactElement;
}

function ConditionalTootip({
  display,
  title,
  children,
}: ConditionalTootipProps) {
  return display ? (
    <Tooltip color="orange" title={title}>
      {children}
    </Tooltip>
  ) : (
    children
  );
}

interface SurveyFiltersProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filter: SurveyFilter) => void;
  totalCount?: number;
  showAdditionalFilters: boolean;
  setShowAdditionalFilters: Function;
}

function SurveyFilters({
  setIsLoading,
  onSubmit,
  totalCount,
  showAdditionalFilters,
  setShowAdditionalFilters,
}: SurveyFiltersProps) {
  const {
    filter,
    setFilter,
    getSurveysCsv,
    filterOptions,
    getFilterOptions,
    hasLoadedOptions,
    searchFilterOptions,
  } = useSurveysContext();

  useEffect(() => {
    const fetchData = async () => {
      await getFilterOptions();
    };

    if (!hasLoadedOptions) {
      setIsLoading(true);
      fetchData().finally(() => {
        setIsLoading(false);
      });
    }
  }, [surveysService]);

  useEffect(() => {
    // if any of additional filters is set, then we want to display them (toggle the switch)
    setShowAdditionalFilters(showAdditionalFilters || !!filter.description);
  }, [filter]);

  const setDateRange = (dates: Array<Dayjs>, itemName: string) => {
    const dateRange = dates
      ? {
          start: dates[0].format(DATE_FORMAT),
          end: dates[1].format(DATE_FORMAT),
        }
      : null;

    setFilter({
      ...filter,
      [itemName]: dateRange,
    });
  };

  const downloadCsv = async (payload: Partial<SurveysPayload>) => {
    const file = await getSurveysCsv(payload);

    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;

    const currentDate = new Date();
    const mm = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = currentDate.getDate().toString().padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    const fullDateString = `${mm}-${dd}-${yyyy}`;

    link.setAttribute('download', `SEER_surveys_${fullDateString}.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const handleOptionsSearch = async (
    fieldName: string,
    filterName: string,
    searchString: string
  ) => {
    await searchFilterOptions(fieldName, filterName, searchString);
  };

  return (
    <Row gutter={[8, 8]} align="bottom">
      <Col md={12} lg={6}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Date Range
          </Typography.Text>
          <DateSelector
            defaultValue={filter.dateRange}
            onDateRangeChange={(dates: Array<Dayjs>) =>
              setDateRange(dates, 'dateRange')
            }
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Request Type
          </Typography.Text>
          <Select
            allowClear
            value={filter.requestType}
            options={filterOptions.requestTypes}
            popupMatchSelectWidth={false}
            placeholder="Select"
            mode="multiple"
            maxTagCount={1}
            onChange={(value) => setFilter({ ...filter, requestType: value })}
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Caller ID
          </Typography.Text>
          <Select
            allowClear
            value={filter.callerId}
            options={filterOptions.callerIds}
            popupMatchSelectWidth={false}
            maxTagCount={0}
            mode="multiple"
            placeholder="Select"
            onChange={(values) => setFilter({ ...filter, callerId: values })}
            showSearch
            onSearch={debounce(
              (value) => handleOptionsSearch('callerID', 'callerIds', value),
              1000
            )}
            filterOption={false}
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Caller Dept
          </Typography.Text>
          <Select
            allowClear
            value={filter.callerDept}
            options={filterOptions.callerDepts}
            popupMatchSelectWidth={false}
            maxTagCount={0}
            mode="multiple"
            placeholder="Select"
            onChange={(value) => setFilter({ ...filter, callerDept: value })}
            showSearch
            onSearch={debounce(
              (value) =>
                handleOptionsSearch('callerDepartment', 'callerDepts', value),
              1000
            )}
            filterOption={false}
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Assignment Group
          </Typography.Text>
          <Select
            allowClear
            value={filter.assignmentGroup}
            options={filterOptions.assignmentGroups}
            popupMatchSelectWidth={false}
            maxTagCount={0}
            mode="multiple"
            placeholder="Select"
            onChange={(value) =>
              setFilter({ ...filter, assignmentGroup: value })
            }
            showSearch
            onSearch={debounce(
              (value) =>
                handleOptionsSearch(
                  'assignmentGroup',
                  'assignmentGroups',
                  value
                ),
              1000
            )}
            filterOption={false}
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Sempra Director
          </Typography.Text>
          <Select
            allowClear
            maxTagCount={0}
            mode="multiple"
            value={filter.sempraDir}
            options={filterOptions.sempraDirs}
            popupMatchSelectWidth={false}
            placeholder="Select"
            onChange={(value) => setFilter({ ...filter, sempraDir: value })}
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Question
          </Typography.Text>
          <Select
            allowClear
            maxTagCount={0}
            mode="multiple"
            value={filter.question}
            options={filterOptions.questions}
            popupMatchSelectWidth={false}
            placeholder="Select"
            onChange={(value) =>
              setFilter({
                ...filter,
                question: value,
              })
            }
          />
        </FormGroup>
      </Col>
      <Col md={6} lg={3}>
        <div className={styles.additionalFiltersButton}>
          Additional Filters
          <Switch
            checked={showAdditionalFilters}
            onChange={(isChecked) => setShowAdditionalFilters(isChecked)}
          />
        </div>
      </Col>

      {/* Additional filters section */}
      {showAdditionalFilters && (
        <>
          <Col md={6} lg={3}>
            <FormGroup>
              <Typography.Text className={styles.inputLabel} strong>
                Scaled Value
              </Typography.Text>
              <Select
                allowClear
                maxTagCount={0}
                mode="multiple"
                value={filter.scaledValue}
                options={filterOptions.scaledValues}
                popupMatchSelectWidth={false}
                placeholder="Select"
                onChange={(value) =>
                  setFilter({ ...filter, scaledValue: value })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6} lg={3}>
            <FormGroup>
              <Typography.Text className={styles.inputLabel} strong>
                Comments
              </Typography.Text>
              <Input
                placeholder="Comments"
                value={filter.stringValue || ''}
                onChange={(e) =>
                  setFilter({ ...filter, stringValue: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6} lg={3}>
            <FormGroup>
              <Typography.Text className={styles.inputLabel} strong>
                Short Description
              </Typography.Text>
              <Input
                placeholder="Description"
                value={filter.description || ''}
                onChange={(e) =>
                  setFilter({ ...filter, description: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </>
      )}
      <Col flex={1} className={styles.searchButtonWrapper}>
        {/* Search Button */}
        <ConditionalTootip
          display={!!totalCount && totalCount > 1000}
          title="Only first 9999 records will be downloaded."
        >
          <Button
            type="default"
            shape="default"
            size="middle"
            icon={<DownloadOutlined />}
            onClick={() => downloadCsv(filter)}
            style={{ marginRight: '5px' }}
          >
            Download
          </Button>
        </ConditionalTootip>

        <Button
          type="primary"
          shape="default"
          size="middle"
          icon={<SearchOutlined />}
          onClick={() => onSubmit(filter)}
        >
          Search Results
        </Button>
      </Col>
    </Row>
  );
}

export default SurveyFilters;
