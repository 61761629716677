import { Tag } from 'antd';

import styles from './HighLowTag.module.scss';

interface HighLowTagProps {
  value: number;
  outliers:
    | {
        lowThreshold: number;
        highThreshold: number;
      }
    | undefined;
}

function HighLowTag({ value, outliers }: HighLowTagProps) {
  if (outliers) {
    const { lowThreshold, highThreshold } = outliers;
    const isLow = value < lowThreshold;
    const isHigh = value > highThreshold;

    let color = '#178FFE';
    let label = 'AVG';
    if (isLow) {
      color = 'success';
      label = 'LOW';
    } else if (isHigh) {
      color = '#fe004b';
      label = 'HIGH';
    }
    return (
      <Tag className={styles.tag} color={color}>
        {label}
      </Tag>
    );
  }
  return null;
}

export default HighLowTag;
