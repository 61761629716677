import { Row, Col, Typography, Button, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { DATE_FORMAT } from '../../constants/formatting';
import { useCallsContext } from '../../context/Calls.context';
import DateSelector from '../DateSelector/DateSelector';
import FormGroup from '../FormGroup/FormGroup';
import Select from '../Select/Select';
import styles from './CallFilters.module.scss';

interface CallFilterProps {
  isLoading: boolean;
  onSearch: () => void;
}

function CallFilters({ isLoading, onSearch }: CallFilterProps) {
  const { filter, setFilter, andOrSwitch, setAndOrSwitch, filterOptions } =
    useCallsContext();

  const setDateRange = (dates: Array<Dayjs>) => {
    const dateRange = dates
      ? {
          start: dates[0].format(DATE_FORMAT),
          end: dates[1].format(DATE_FORMAT),
        }
      : null;

    setFilter({
      ...filter,
      dateRange,
    });
  };

  return (
    <Row gutter={[8, 8]} align="bottom">
      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Date Range
          </Typography.Text>
          <DateSelector onDateRangeChange={setDateRange} />
        </FormGroup>
      </Col>

      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Interaction Type
          </Typography.Text>
          <Select
            allowClear
            placeholder="Select"
            value={filter.interactionType}
            options={filterOptions.interactionTypes}
            onChange={(value) =>
              setFilter({ ...filter, interactionType: value })
            }
          />
        </FormGroup>
      </Col>

      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Call Duration
          </Typography.Text>
          <Select
            value={filter.callDuration}
            options={filterOptions.callDurationRanges}
            onChange={(value) => setFilter({ ...filter, callDuration: value })}
          />
        </FormGroup>
      </Col>

      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Call Tags
          </Typography.Text>
          <Select
            mode="multiple"
            showSearch
            allowClear
            maxTagCount={1}
            maxTagTextLength={5}
            placeholder="Search and Select"
            value={filter.callTags}
            options={filterOptions.tags}
            onChange={(value) => setFilter({ ...filter, callTags: value })}
          />
        </FormGroup>
      </Col>

      {/* <Col md={8} lg={4}>
            <FormGroup>
              <Typography.Text className={styles.inputLabel} strong>
                Agent Name
              </Typography.Text>
              <Select
                showSearch
                allowClear
                placeholder="Search and Select"
                value={filter.agentName}
                options={filterOptions.agentList}
                onChange={(value) => setFilter({ ...filter, agentName: value })}
              />
            </FormGroup>
          </Col> */}

      {/* <Col lg={4}>
            <FormGroup>
              <Typography.Text className={styles.inputLabel} strong>
                Ending Sentiment
              </Typography.Text>
              <Select
                allowClear
                placeholder="Select From List"
                value={filter.endingSentiment}
                options={filterOptions.sentiments}
                onChange={(value) =>
                  setFilter({ ...filter, endingSentiment: value })
                }
              />
            </FormGroup>
          </Col> */}

      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Agent Sentiment
          </Typography.Text>
          <Select
            allowClear
            placeholder="Select"
            value={filter.agentSentiment}
            options={filterOptions.sentiments}
            onChange={(value) =>
              setFilter({ ...filter, agentSentiment: value })
            }
          />
        </FormGroup>
      </Col>

      <Col md={8} lg={4}>
        <FormGroup>
          <Typography.Text className={styles.inputLabel} strong>
            Caller Sentiment
          </Typography.Text>
          <Select
            allowClear
            placeholder="Select"
            value={filter.callerSentiment}
            options={filterOptions.sentiments}
            onChange={(value) =>
              setFilter({ ...filter, callerSentiment: value })
            }
          />
        </FormGroup>
      </Col>

      <Col flex={1}>
        <Row gutter={[8, 8]} justify="space-between" align="bottom">
          <Col md={8} lg={4}>
            <div className={styles.matchAnyTagSwitch}>
              Match any tag
              <Switch
                checked={andOrSwitch}
                onChange={(isChecked) => setAndOrSwitch(isChecked)}
              />
            </div>
          </Col>

          <Col md={8} lg={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ width: '100%' }}
              onClick={onSearch}
              loading={isLoading}
            >
              SEARCH FILTERS
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CallFilters;
