import { ChartData } from 'chart.js';
import {
  CustomBubbleDataPoint,
  TicketResTime,
  Timeframe,
  TimeframeOptionType,
  VisualFilters,
} from '../types/Visuals';
import Color from '../types/enums/Colors';
import { OPTIONS_START_YEAR } from '../constants/visuals';

/**
 * Function specific for TicketResTimesChart
 * Will process TicketResTime array and structure them to a way that can be used in Bubble hart
 * @param ticketResolutionTimes
 * @param timeModifier - value in "seconds" that is used to modify Y axis values (mean resolution times)
 * @returns chart data which can be passed to Bubble chart component for visualization
 */
export const getTicketResTimesChartData = (
  ticketResolutionTimes: TicketResTime[],
  timeModifier: number = 1
): ChartData<'bubble'> => {
  const datasets = ticketResolutionTimes.map(
    ({ groupName, ticketCount, meanResolutionTime }) => ({
      label: groupName,
      data: [
        {
          x: ticketCount,
          // both meanResolutionTime and timeModifier values are in seconds
          y: Math.ceil(meanResolutionTime / timeModifier),
          r: 10,
          // custom "duration" value which will keep exact values for visualization (currently used in tooltip)
          duration: meanResolutionTime / timeModifier,
        } as CustomBubbleDataPoint,
      ],
      backgroundColor: Color.ChartRed,
    })
  );

  return {
    datasets,
  };
};

/**
 * Returns unique sorted axes values out of provided chart data
 * @param chartData - chart data object we will parse to get axes arrays only
 * @returns 2 arrays of unique sorted X and Y axis values (1 array per axis)
 */
export const getUniqueSortedAxesData = (chartData: ChartData<'bubble'>) => {
  // get only unique values
  const uniqueX = [
    ...new Set(chartData.datasets.map((dataset) => dataset.data[0].x)),
  ];
  const uniqueY = [
    ...new Set(chartData.datasets.map((dataset) => dataset.data[0].y)),
  ];
  // sort values in ascending order
  const sortedX = uniqueX.sort((a, b) => a - b);
  const sortedY = uniqueY.sort((a, b) => a - b);

  return {
    sortedY,
    sortedX,
  };
};

/**
 * Gives value in seconds of provided timeframe
 * @param timeframe
 * @returns value in seconds
 */
export const getSecondsOf = (timeframe: Timeframe) => {
  switch (timeframe) {
    case 'days':
      return 86400;
    case 'hours':
      return 3600;
    default:
      return 1;
  }
};

export const hexToRgb = (hex: string, opacity = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

/**
 * Generates an array of monthly timeframe options for the last 5 years in descending order.
 *
 * @returns {DefaultOptionType[]} An array of monthly timeframe options.
 * @example
 * Example result with 1 object:
 * [
 *   {
 *     label: 'Jan FY22',
 *     value: '2022-01',
 *   }
 * ]
 */
export const generateMonthlyOptions = (until: string) => {
  const monthlyOptions: TimeframeOptionType[] = [];
  // Get the current year
  const currentYear = new Date().getFullYear();
  // Get the current month (0-11)
  const currentMonth = new Date().getMonth();
  // Generate an array of years from OPTIONS_START_YEAR to currentYear
  const years = Array.from(
    { length: currentYear - OPTIONS_START_YEAR + 1 },
    (_, index) => currentYear - index
  );

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  years.forEach((year) => {
    monthNames.forEach((month, monthIndex) => {
      // Check if the year and month are within the last 5 years and not beyond the current month
      if (
        year < currentYear ||
        (year === currentYear && monthIndex <= currentMonth)
      ) {
        const monthlyLabel = `${month.substring(0, 3)} FY${year
          .toString()
          .slice(-2)}`;
        const monthNumber = `${monthIndex + 1}`;
        const monthlyValue = `${year.toString()}-${monthNumber.padStart(
          2,
          '0'
        )}`;
        // Add new option to the array
        monthlyOptions.push({
          label: monthlyLabel,
          value: monthlyValue,
        });
      }
    });
  });

  // Sort monthlyOptions in descending order based on the value property
  monthlyOptions.sort((a, b) => b.value.localeCompare(a.value));

  // Remove option after optional until param

  return until !== ''
    ? monthlyOptions.filter((item) => new Date(item.value) < new Date(until))
    : monthlyOptions;
};

/**
 * Generates an array of quarterly timeframe options for the last 5 years in descending order.
 *
 * @returns {DefaultOptionType[]} An array of quarterly timeframe options.
 * @example
 * Example result with 1 object:
 * [
 *   {
 *     label: 'Q3 FY22',
 *     value: '2022-3',
 *   }
 * ]
 */
export const generateQuarterlyOptions = () => {
  const quarterlyOptions: TimeframeOptionType[] = [];
  // Get the current year
  const currentYear = new Date().getFullYear();
  // Get the current month (0-11)
  const currentMonth = new Date().getMonth();
  // Determine the current quarter (1-4)
  const currentQuarter = Math.ceil((currentMonth + 1) / 3);
  // Generate an array of years from OPTIONS_START_YEAR to currentYear
  const years = Array.from(
    { length: currentYear - OPTIONS_START_YEAR + 1 },
    (_, index) => currentYear - index
  );

  years.forEach((year) => {
    for (let quarter = 1; quarter <= 4; quarter += 1) {
      // Check if the year and quarter are within the last 5 years and not beyond the current quarter
      if (
        year < currentYear ||
        (year === currentYear && quarter <= currentQuarter)
      ) {
        const quarterlyLabel = `Q${quarter} FY${year.toString().slice(-2)}`;
        const quarterlyValue = `${year.toString()}-${quarter}`;
        quarterlyOptions.push({
          label: quarterlyLabel,
          value: quarterlyValue,
        });
      }
    }
  });

  // Sort quarterlyOptions in descending order based on the value property
  quarterlyOptions.sort((a, b) => b.value.localeCompare(a.value));

  return quarterlyOptions;
};

/**
 * Generates an array of yearly timeframe options for the last 5 years in descending order.
 *
 * @returns {TimeframeOptionType[]} An array of yearly timeframe options.
 *
 * @example
 * // Example result with 1 object:
 * // [
 * //   {
 * //     label: 'FY23',
 * //     value: '2023',
 * //   }
 * // ]
 */
export const generateYearlyOptions = () => {
  const yearlyOptions: TimeframeOptionType[] = [];
  // Get the current year
  const currentYear = new Date().getFullYear();
  // Get the current month (0-11)
  const currentMonth = new Date().getMonth();
  // Generate an array of years from OPTIONS_START_YEAR to currentYear
  const years = Array.from(
    { length: currentYear - OPTIONS_START_YEAR + 1 },
    (_, index) => currentYear - index
  );

  years.forEach((year) => {
    // Check if the year is within the last 5 years or the current year (if the month is not over)
    if (year < currentYear || (year === currentYear && currentMonth >= 0)) {
      const yearlyLabel = `FY${year.toString().slice(-2)}`;
      const yearlyValue = year.toString();
      // Add the yearly option to the array
      yearlyOptions.push({
        label: yearlyLabel,
        value: yearlyValue,
      });
    }
  });

  // Sort yearlyOptions in descending order based on the value property
  yearlyOptions.sort((a, b) => b.value.localeCompare(a.value));

  return yearlyOptions;
};

/**
 * Parses and processes visual filters to extract specific time-related fields.
 *
 * @param {VisualFilters} filters - The visual filters to be processed.
 * @returns {Object} An object containing time-related fields and additional filter properties.
 */
export const parseFilters = (filters: VisualFilters) => {
  // Destructure the filters to extract timeframeType, timeframe, and the rest of the properties.
  const { timeframeType, timeframe, ...rest } = filters;

  // Check if both timeframeType and timeframe are present.
  if (timeframe && timeframeType) {
    switch (timeframeType) {
      case 'monthly': {
        // Split the timeframe into year and month components.
        const [year, month] = timeframe.split('-');
        return {
          // Extracted time-related fields for monthly data.
          openedMonth: Number(month),
          openedYear: Number(year),
          ...rest, // Include the remaining filter properties.
        };
      }
      case 'quarterly': {
        // Split the timeframe into year and quarter components.
        const [year, quarter] = timeframe.split('-');
        return {
          // Extracted time-related fields for quarterly data.
          openedQuarter: Number(quarter),
          openedYear: Number(year),
          ...rest, // Include the remaining filter properties.
        };
      }
      case 'yearly': {
        // The timeframe represents a full year.
        const year = timeframe;
        return {
          // Extracted time-related field for yearly data.
          openedYear: Number(year),
          ...rest, // Include the remaining filter properties.
        };
      }
      default:
        return { ...rest }; // No recognized timeframeType, include the remaining filter properties.
    }
  }

  // If timeframe or timeframeType is missing, return the filters without time-related fields.
  return { ...rest };
};

// Function used in wordcloud to adjust its size
export const getWordScale = (totalWords: number) => {
  if (totalWords <= 75) return 2.8;
  if (totalWords > 75 && totalWords <= 100) return 1.65;
  if (totalWords > 100 && totalWords <= 125) return 1.5;
  if (totalWords > 125 && totalWords <= 150) return 1.35;
  return 1.1;
};
