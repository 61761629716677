import { Col, Empty, Radio, Row, Slider, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ChartData, ActiveElement } from 'chart.js';
import { useEffect, useMemo, useState } from 'react';
import { Bubble } from 'react-chartjs-2';
import { CustomBubbleDataPoint, Timeframe } from '../../types/Visuals';
import styles from './TicketResTimesChart.module.scss';
import {
  getSecondsOf,
  getTicketResTimesChartData,
  getUniqueSortedAxesData,
} from '../../utils/visual-utils';
import { useDashboardContext } from '../../context/Dashboard.context';
import { useTicketsContext } from '../../context/Tickets.context';
import Box from '../Box/Box';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

function TicketResTimesChart() {
  const {
    visualStates: { ticketResolutionTimes },
    summaryDateRange,
    getVisualByName,
  } = useDashboardContext();
  const { navigateToTickets } = useTicketsContext();

  const [chartData, setChartData] = useState<ChartData<'bubble'>>();
  const [timeframe, setTimeframe] = useState<Timeframe>('days');
  const [timeRange, setTimeRange] = useState<number[]>();
  const [countRange, setCountRange] = useState<number[]>();
  const [resolutionTimes, setResolutionTimes] = useState<number[]>([]);
  const [ticketCounts, setTicketCounts] = useState<number[]>([]);

  // whenever original data or timeframe is changed
  useEffect(() => {
    if (ticketResolutionTimes.data.ticketResTimes.length > 0) {
      // if chart data are available, then recalculate chart data
      const ticketResTimesData = getTicketResTimesChartData(
        ticketResolutionTimes.data.ticketResTimes,
        getSecondsOf(timeframe)
      );
      setChartData(ticketResTimesData);

      // get axes data - used to populate sliders
      const { sortedX, sortedY } = getUniqueSortedAxesData(ticketResTimesData);
      setResolutionTimes(sortedY);
      setTimeRange([0, sortedY.length - 1]);
      setTicketCounts(sortedX);
      setCountRange([0, sortedX.length - 1]);
    } else {
      // else no data provided for the chart
      setChartData(undefined);
    }
  }, [timeframe, ticketResolutionTimes]);

  const resolutionTimeMarks = useMemo(
    () =>
      resolutionTimes.reduce(
        (previous, current, index) => ({
          ...previous,
          [index]:
            index === 0 || index === resolutionTimes.length - 1
              ? `${current} ${timeframe}`
              : ' ',
        }),
        {}
      ),
    [resolutionTimes]
  );

  const ticketCountMarks = useMemo(
    () =>
      ticketCounts.reduce(
        (previous, current, index) => ({
          ...previous,
          [index]:
            index === 0 || index === ticketCounts.length - 1
              ? `${current} tickets`
              : ' ',
        }),
        {}
      ),
    [ticketCounts]
  );

  const handleAsgGroupClick = (elements: ActiveElement[]) => {
    if (chartData) {
      const assignmentGroups = elements.map(
        (element) => chartData.datasets[element.datasetIndex].label || ''
      );
      // Navigate to Ticket Insights page and pre-populate filter and show results
      navigateToTickets({
        dateRange: summaryDateRange,
        assignmentGroup: assignmentGroups,
      });
    }
  };

  return !ticketResolutionTimes.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <>
      <Row justify="space-between">
        <Typography.Text className={styles.title}>
          Ticket resolution time by assignment group
        </Typography.Text>

        <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
          <Col>
            <ChartFilters
              value={{
                dataSource: ticketResolutionTimes.dataSource,
                timeframe: ticketResolutionTimes.timeframe,
                timeframeType: ticketResolutionTimes.timeframeType,
              }}
              options={[
                { label: 'Incident', value: 'incidents' },
                { label: 'Request', value: 'requests' },
              ]}
              // optionsUntil="2023-09"
              onChange={(value) => {
                getVisualByName(VisualName.TicketResolutionTimes, [], value);
              }}
            />
          </Col>

          <Col>
            <HelpPopover placement="bottomRight">
              <Img
                src="./images/help/ticket-resolution-time-by-asg-group.jpeg"
                alt="Description of Ticket resolution time by assignment group chart"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '1000px',
                }}
              />
            </HelpPopover>
          </Col>
        </Row>
      </Row>
      <Row>
        <Box type="secondary" className={styles.flexBox}>
          {/* Filter section */}
          <Row className={styles.filter} gutter={16} align="top">
            <Col>
              <Radio.Group
                onChange={({ target: { value } }) => setTimeframe(value)}
                value={timeframe}
                buttonStyle="solid"
                disabled={!chartData}
              >
                <Radio.Button value="days">Days</Radio.Button>
                <Radio.Button value="hours">Hours</Radio.Button>
              </Radio.Group>
            </Col>

            <Col flex="auto">
              <Slider
                className={styles.ticketResTimesSlider}
                range
                value={timeRange}
                onChange={setTimeRange}
                max={resolutionTimes.length - 1}
                marks={resolutionTimeMarks}
                tooltip={{
                  formatter: (index) =>
                    index !== undefined &&
                    `${resolutionTimes[index]} ${timeframe}`,
                }}
                disabled={!chartData}
                step={null}
              />
              <Slider
                className={styles.ticketResTimesSlider}
                range
                value={countRange}
                onChange={setCountRange}
                max={ticketCounts.length - 1}
                marks={ticketCountMarks}
                tooltip={{
                  formatter: (index) =>
                    index !== undefined && `${ticketCounts[index]} tickets`,
                }}
                disabled={!chartData}
                step={null}
              />
            </Col>
          </Row>

          {/* Chart section */}
          {!chartData ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            <Bubble
              style={{ maxHeight: 400 }}
              options={{
                clip: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    usePointStyle: true,
                    boxPadding: 3,
                    bodySpacing: 4,
                    callbacks: {
                      label: (tooltipItem) => {
                        const {
                          label,
                          data: [metadata],
                        } = tooltipItem.dataset;
                        const { x: ticketCount, duration } =
                          metadata as CustomBubbleDataPoint;

                        return `${label}: ${ticketCount} tickets, ${duration.toFixed(
                          2
                        )} ${timeframe}`;
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    min: timeRange && resolutionTimes[timeRange[0]],
                    max: timeRange && resolutionTimes[timeRange[1]],
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      color: 'white',
                      text: `Mean resolution time (in ${timeframe})`,
                    },
                    ticks: {
                      color: 'white',
                    },
                    type: 'logarithmic',
                  },
                  x: {
                    min: countRange && ticketCounts[countRange[0]],
                    max: countRange && ticketCounts[countRange[1]],
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      color: 'white',
                      text: 'Ticket count',
                    },
                    ticks: {
                      minRotation: 35,
                      color: 'white',
                    },
                    type: 'logarithmic',
                  },
                },
                onClick: (_, elements) => handleAsgGroupClick(elements),
              }}
              data={chartData}
            />
          )}
        </Box>
      </Row>
    </>
  );
}

export default TicketResTimesChart;
