import { Typography } from 'antd';
import LoginButton from '../LoginButton/LoginButton';
import styles from './LoginForm.module.scss';

import icon from '../../assets/icons/sdge-logo.svg';

function LoginForm() {
  return (
    <div className={styles.loginForm}>
      <div className={styles.section}>
        <span className={styles.logo}>
          <img alt="SDGE logo" src={icon} />
        </span>
        <Typography.Title level={4}>SEER DASHBOARD</Typography.Title>
      </div>
      <div className={styles.section}>
        <Typography.Title>Login</Typography.Title>
        <Typography.Text className={styles.info}>
          You will be redirected to Sempra&apos;s Single-Sign-On site. Please
          use your Sempra credentials to log in.
        </Typography.Text>
        <LoginButton />
      </div>
      <Typography.Text className={styles.footnote}>
        This system is for authorized use only. Any activities on this system
        may be recorded and monitored. Use of this system in excess of authority
        may result in termination and or prosecution.
      </Typography.Text>
    </div>
  );
}

export default LoginForm;
