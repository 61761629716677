import { Spin, Row, Col, Typography, Empty } from 'antd';
import Chart from 'react-apexcharts';
import { LoadingOutlined } from '@ant-design/icons';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { useDashboardContext } from '../../context/Dashboard.context';
import styles from './TicketsTreeMapChart.module.scss';
import Box from '../Box/Box';
import Color from '../../types/enums/Colors';
import TicketsTreeMapLegend from './TicketsTreeMapLegend';
import { TreeMapLegendItem } from '../../types/Visuals';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

function TicketsTreeMapChart() {
  const [usedColors, setUsedColors] = useState<string[]>([]);
  const [seriesData, setSeriesData] = useState<ApexAxisChartSeries>([]);
  const {
    visualStates: { aggregatedIncidentsTicketsBySegment },
    getVisualByName,
  } = useDashboardContext();
  const { legendData, graphData } = aggregatedIncidentsTicketsBySegment.data;

  useEffect(() => {
    setSeriesData(graphData);
    const startingColors = legendData.map(({ color }) => color);
    setUsedColors(startingColors);
  }, [graphData, legendData]);

  const handleLegendClick = (hiddenItems: TreeMapLegendItem[]) => {
    const shownItems = graphData.filter(
      ({ name }) =>
        name && !hiddenItems.find((hiddenItem) => hiddenItem.label === name)
    );

    setSeriesData(shownItems);
    const shownColors = legendData
      .filter(
        ({ label }) =>
          !hiddenItems.find((hiddenItem) => hiddenItem.label === label)
      )
      .map(({ color }) => color);
    setUsedColors(shownColors);
  };

  // graph configuration options
  const options: ApexOptions = {
    plotOptions: {
      treemap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.9,
            },
          ],
        },
      },
    },
    chart: {
      offsetX: 8,
      offsetY: -12,
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      id: 'incidentsTicketsTreeMapChart',
    },
    tooltip: {
      fillSeriesColor: true,
    },
    colors: usedColors,
    xaxis: {
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisTicks: {
        show: false,
      },
    },
    stroke: {
      colors: [Color.BgSecondary],
    },
    dataLabels: {
      style: {
        colors: [Color.Text],
      },
      offsetY: -2,
    },
    fill: { opacity: 0.8 },
  };

  return !aggregatedIncidentsTicketsBySegment.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <>
      <Row justify="space-between">
        <Typography.Text className={styles.title}>
          Tickets by Segmentation
        </Typography.Text>

        <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
          <Col>
            <ChartFilters
              value={{
                dataSource: aggregatedIncidentsTicketsBySegment.dataSource,
                timeframe: aggregatedIncidentsTicketsBySegment.timeframe,
                timeframeType:
                  aggregatedIncidentsTicketsBySegment.timeframeType,
              }}
              options={[
                { label: 'Incident', value: 'incidents' },
                { label: 'Request', value: 'requests' },
                { label: 'Survey', value: 'surveys' },
              ]}
              onChange={(value) => {
                getVisualByName(
                  VisualName.AggregatedIncidentsTicketsBySegment,
                  {
                    legendData: [],
                    graphData: [],
                  },
                  value
                );
              }}
            />
          </Col>

          <Col>
            <HelpPopover placement="bottomRight">
              <Img
                src="./images/help/tickets-by-segmentation.jpeg"
                alt="Description of Tickets by Segmentation chart"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '850px',
                }}
              />
            </HelpPopover>
          </Col>
        </Row>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Box type="secondary">
            {/* Chart section */}
            {seriesData.length === 0 && legendData.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <>
                <Chart
                  options={options}
                  series={seriesData}
                  type="treemap"
                  height={450}
                />
                <TicketsTreeMapLegend
                  legend={legendData}
                  onLegendItemClick={handleLegendClick}
                />
              </>
            )}
          </Box>
        </Col>
      </Row>
    </>
  );
}
export default TicketsTreeMapChart;
