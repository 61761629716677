import { ReactNode, forwardRef } from 'react';
import Color from '../../types/enums/Colors';
import styles from './BorderedBox.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: { [key: string]: string };
  type?: string;
}

const BorderedBox = forwardRef(
  (
    { children = '', type = 'primary', className = '', style = {} }: Props,
    ref
  ) => {
    const backgroundColor =
      type === 'primary' ? Color.BgPrimary : Color.BgSecondary;

    return (
      <div
        className={`${styles.box} ${
          styles[`borderBox${type === 'primary' ? 'Primary' : 'Secondary'}`]
        } ${className}`}
        style={{ ...style, backgroundColor }}
        ref={ref as React.RefObject<HTMLDivElement>}
      >
        {children}
      </div>
    );
  }
);

export default BorderedBox;
