import { Row, Col, Typography, Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import {
  ScaleChartOptions,
  LineControllerChartOptions,
  DatasetChartOptions,
  PluginChartOptions,
  ElementChartOptions,
  CoreChartOptions,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import Box from '../Box/Box';
import styles from './Summary.module.scss';
import { useDashboardContext } from '../../context/Dashboard.context';
import HighLowTag from '../HighLowTag/HighLowTag';
import { hexToRgb } from '../../utils/visual-utils';
import { LINE_CHART_COLORS } from '../../constants/visuals';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import { msToTime } from '../../utils/date-utils';
import SummaryChart from '../SentimentChart/SentimentChart';
import OverallSentimentTrend from '../OverallSentimentTrend/OverallSentimentTrend';

const options: _DeepPartialObject<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems) => `${tooltipItems[0].label}. day`,
        label: (tooltopItem) =>
          `${tooltopItem.dataset.label}: ${tooltopItem.formattedValue} tickets`,
      },
    },
  },
  scales: {
    x: {
      border: {
        color: 'white',
      },
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
    y: {
      beginAtZero: true,
      border: {
        color: 'white',
      },
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
      title: {
        display: true,
        color: 'white',
        text: 'TICKET VOLUME',
      },
    },
  },
};

function Summary() {
  const { summaryCounts, summaryGraph, summaryOutliers, summaryTimeframe } =
    useDashboardContext();

  const chartData = useMemo<ChartData<'line'> | null>(() => {
    if (summaryGraph) {
      let summaryData;
      switch (summaryTimeframe) {
        case '30-day':
          summaryData = {
            opened: summaryGraph.default.opened,
            resolved: summaryGraph.default.resolved,
          };
          break;
        case '7-day': {
          summaryData = {
            opened: summaryGraph.default.opened.slice(-7),
            resolved: summaryGraph.default.resolved.slice(-7),
          };
          break;
        }
        case '1-day': {
          summaryData = {
            opened: summaryGraph.default.opened.slice(-1),
            resolved: summaryGraph.default.resolved.slice(-1),
          };
          break;
        }
        case 'custom': {
          summaryData = {
            opened: summaryGraph.custom ? summaryGraph.custom.opened : [],
            resolved: summaryGraph.custom ? summaryGraph.custom.resolved : [],
          };
          break;
        }
        default:
      }

      if (summaryData) {
        const labels = [...Array(summaryData.opened.length + 1).keys()].slice(
          1
        );
        const datasets = [
          {
            label: 'Resolved',
            data: summaryData.resolved.map((volumeInfo) => volumeInfo.count),
            fill: true,
            borderColor: LINE_CHART_COLORS[2] || '#ffffff',
            backgroundColor: hexToRgb(LINE_CHART_COLORS[2] || '#ffffff', 0.2),
          },
          {
            label: 'Opened',
            data: summaryData.opened.map((volumeInfo) => volumeInfo.count),
            fill: true,
            borderColor: LINE_CHART_COLORS[3] || '#ffffff',
            backgroundColor: hexToRgb(LINE_CHART_COLORS[3] || '#ffffff', 0.2),
          },
        ];

        return {
          labels,
          datasets,
        };
      }
    }

    return null;
  }, [summaryGraph, summaryTimeframe]);

  // can be used to switch between views 30/7/1 day
  const displayData = summaryCounts[summaryTimeframe];
  const selectedOutliers = summaryOutliers[summaryTimeframe];

  const getNoDataElement = () =>
    summaryTimeframe !== 'custom' ? (
      <div className={styles.loading}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
      </div>
    ) : (
      <div className={styles.noDateRange}>
        <Typography.Text className={styles.tileTitle}>
          Please select the date range to display data
        </Typography.Text>
      </div>
    );

  return displayData ? (
    <Row gutter={[8, 8]}>
      <Col md={24} lg={16}>
        <Row gutter={8}>
          <Col span={5} className={styles.flexCol}>
            <Typography.Text className={styles.tileTitle}>
              Tickets
            </Typography.Text>
          </Col>
          <Col span={9} className={styles.flexCol}>
            <Typography.Text className={styles.tileTitle}>
              Calls
            </Typography.Text>
          </Col>
          <Col span={10} className={styles.flexCol}>
            <Typography.Text className={styles.tileTitle}>
              Chats
            </Typography.Text>

            <HelpPopover className={styles.summaryBoxesHelp}>
              <Img
                src="./images/help/summary-boxes.jpeg"
                alt="Description of Tickets, Calls, Chats summary boxes"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '1000px',
                }}
              />
            </HelpPopover>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={5}>
            <Box type="secondary" className={styles.boxContainer}>
              <Box className={styles.boxContainerColumn}>
                <Typography.Text className={styles.boxNumber}>
                  {displayData.tickets.resolved}
                  <HighLowTag
                    value={displayData.tickets.resolved}
                    outliers={selectedOutliers?.tickets.opened}
                  />
                </Typography.Text>
                <Typography.Text className={styles.boxTitle}>
                  Tickets Resolved
                </Typography.Text>
              </Box>
              <Box className={styles.boxContainerColumn}>
                <Typography.Text className={styles.boxNumber}>
                  {displayData.tickets.opened}
                  <HighLowTag
                    value={displayData.tickets.opened}
                    outliers={selectedOutliers?.tickets.opened}
                  />
                </Typography.Text>
                <Typography.Text className={styles.boxTitle}>
                  Tickets Opened
                </Typography.Text>
              </Box>
            </Box>
          </Col>
          <Col span={9}>
            <Box type="secondary" className={styles.boxContainer}>
              <Box className={styles.boxContainerDouble}>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {displayData.calls.count}
                    <HighLowTag
                      value={displayData.calls.count}
                      outliers={selectedOutliers?.calls}
                    />
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Total Calls
                  </Typography.Text>
                </div>
                <div className={styles.flexCol}>
                  <SummaryChart sentimentInput={displayData.calls.sentiment} />
                  <Typography.Text
                    className={`${styles.boxTitle} ${styles.overallSentimentTrend}`}
                  >
                    Overall Sentiment
                    <OverallSentimentTrend
                      sentimentTrend={
                        displayData.calls.sentiment.sentimentTrend
                      }
                    />
                  </Typography.Text>
                </div>
              </Box>
              <Box className={styles.boxContainerDouble}>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {msToTime(displayData.calls.avgTime, false)}
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Average Talk Time
                  </Typography.Text>
                </div>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {msToTime(displayData.calls.avgSilence, false)}
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Avg. Silence &gt; 30 sec.
                  </Typography.Text>
                </div>
              </Box>
            </Box>
          </Col>
          <Col span={10}>
            <Box type="secondary" className={styles.boxContainer}>
              <Box className={styles.boxContainerDouble}>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {displayData.chats.count}
                    <HighLowTag
                      value={displayData.chats.count}
                      outliers={selectedOutliers?.chats}
                    />
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Total Chats
                  </Typography.Text>
                </div>
                <div className={styles.flexCol}>
                  <SummaryChart sentimentInput={displayData.chats.sentiment} />
                  <Typography.Text
                    className={`${styles.boxTitle} ${styles.overallSentimentTrend}`}
                  >
                    Overall Sentiment
                    <OverallSentimentTrend
                      sentimentTrend={
                        displayData.chats.sentiment.sentimentTrend
                      }
                    />
                  </Typography.Text>
                  {/* <Tag className={styles.boxTag} color="success">
                    POSITIVE
                  </Tag> */}
                </div>
              </Box>
              <Box className={styles.boxContainerDouble}>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {msToTime(displayData.chats.avgTime, false)}
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Average Chats Time
                  </Typography.Text>
                </div>
                <div className={styles.flexCol}>
                  <Typography.Text className={styles.boxNumber}>
                    {msToTime(displayData.chats.avgAgentResponseTime, false)}
                  </Typography.Text>
                  <Typography.Text className={styles.boxTitle}>
                    Avg. Agent Response Time
                  </Typography.Text>
                </div>
              </Box>
            </Box>
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={8}>
        <Row gutter={8}>
          <Col span={24}>
            <Row justify="space-between">
              <Typography.Text className={styles.tileTitle}>
                <span style={{ color: LINE_CHART_COLORS[2] }}>Resolved</span>{' '}
                vs. <span style={{ color: LINE_CHART_COLORS[3] }}>Opened</span>{' '}
                {summaryTimeframe} ticket volume
              </Typography.Text>

              <HelpPopover placement="bottomRight">
                <Img
                  src="./images/help/summary-chart.jpeg"
                  alt="Description of Resolved vs Opened Summary chart"
                  style={{
                    color: 'black',
                    width: '90vw',
                    maxWidth: '1000px',
                  }}
                />
              </HelpPopover>
            </Row>
          </Col>
        </Row>
        <Row gutter={8}>
          {/* Ticket volume chart */}
          <Box
            type="secondary"
            className={styles.boxContainerGraph}
            style={{ margin: '0 4px' }}
          >
            {!chartData ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <Line options={options} data={chartData} />
            )}
          </Box>
        </Row>
      </Col>
    </Row>
  ) : (
    getNoDataElement()
  );
}

export default Summary;
