enum Color {
  BgPrimary = '#031627',
  BgSecondary = '#0E263B',
  Primary = '#178FFE',
  Text = '#FFFFFF',
  Divider = 'rgba(255, 255, 255, 0.2)',
  Success = '#6cca98',
  BgSuccess = 'rgba(8, 136, 86, 0.5)',
  Neutral = '#d48806',
  BgNeutral = '#f0d081',
  Error = '#cf1322',
  BgError = '#f67b73',
  ChartRed = '#df134c80', // with 50% opacity
}

export default Color;
