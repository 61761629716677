import LoginForm from '../../components/LoginForm/LoginForm';
import styles from './Login.module.scss';

function Login() {
  return (
    <div className={styles.login}>
      <div className={styles.loginForm}>
        <LoginForm />
      </div>
      <div className={styles.loginBackground} />
    </div>
  );
}

export default Login;
