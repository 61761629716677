const TICKETS_BY_CATEGORY_FILENAMES = [
  'category_level_count',
  'subcategory_level_count',
  'subcategory_level_percent',
];
const BAR_CHART_COLORS = {
  primary: [
    '#df134c',
    '#c81144',
    '#b20f3c',
    '#9c0d35',
    '#850b2d',
    '#6f0926',
    '#59071e',
  ],
  secondary: [
    '#426fe5',
    '#2a5de2',
    '#134cdf',
    '#1144c8',
    '#0f3cb2',
    '#0d359c',
    '#0b2d85',
  ],
};

const LINE_CHART_COLORS = [
  '#58B947',
  '#DC7300',
  '#178FFE',
  '#DF134C',
  '#666366',
  '#088856',
  '#F39B31',
  '#4ACCD4',
  '#C52424',
  '#EEEEEE',
];

const TIMEFRAME_TYPE_OPTIONS = [
  { label: 'Month', value: 'monthly' },
  { label: 'Quarter', value: 'quarterly' },
  { label: 'Year', value: 'yearly' },
];

const OPTIONS_START_YEAR = 2018;

export {
  TICKETS_BY_CATEGORY_FILENAMES,
  BAR_CHART_COLORS,
  LINE_CHART_COLORS,
  TIMEFRAME_TYPE_OPTIONS,
  OPTIONS_START_YEAR,
};
