import { Line } from 'react-chartjs-2';
import { TicketTrendsByChannelTimeline } from '../../types/Visuals';
import { LINE_CHART_COLORS } from '../../constants/visuals';
import { hexToRgb } from '../../utils/visual-utils';

interface LineChartProps {
  lineChartData: TicketTrendsByChannelTimeline | null;
  yAxisLabel?: string;
}
function LineChart({ lineChartData, yAxisLabel = '' }: LineChartProps) {
  if (!lineChartData) return null;

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
        title: {
          display: true,
          color: 'white',
          text: yAxisLabel,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          boxHeight: 8,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: lineChartData.ticks,
    datasets: lineChartData.datasetLabels.map((dataset, index) => {
      const filteredData = lineChartData.data.filter(
        ({ source }) => source === dataset
      );
      const dataEntries = lineChartData.ticks.map((tick) => {
        const dateEntry = filteredData.find((entry) => entry.date === tick);

        return dateEntry ? dateEntry.count : 0;
      });
      return {
        fill: true,
        label: dataset,
        data: dataEntries,
        borderColor: LINE_CHART_COLORS[index] || '#ffffff',
        backgroundColor: hexToRgb(LINE_CHART_COLORS[index] || '#ffffff', 0.2),
      };
    }),
  };

  return <Line options={options} data={data} />;
}

export default LineChart;
