import { useState } from 'react';
import { Spin, Col, Row, Popover, Typography, Empty } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Box from '../Box/Box';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import BarChart from '../BarChart/BarChart';
import Select from '../Select/Select';
import { useDashboardContext } from '../../context/Dashboard.context';
import { BAR_CHART_COLORS } from '../../constants/visuals';
import styles from './HighLowFrequencyUsersChart.module.scss';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

const { secondary } = BAR_CHART_COLORS;

const popupContent = (
  <div className={styles.popoverContent}>
    <p>Clicking on labels can activate/deactivate data groups.</p>
  </div>
);

function HighLowFrequencyUsersChart() {
  const [selectedCategory, setSelectedCategory] = useState();

  const {
    visualStates: { countUserFrequencyType },
    getVisualByName,
  } = useDashboardContext();

  const { sourceFreqTypeCounts, userFreqTypeCounts } =
    countUserFrequencyType.data;

  if (!sourceFreqTypeCounts || !userFreqTypeCounts) return null;

  const categoriesNames = sourceFreqTypeCounts.map(({ source }) => source);

  const subCategoriesAmounts: any = {};
  userFreqTypeCounts.forEach(({ volume, userFrequencyType }) => {
    if (!(userFrequencyType in subCategoriesAmounts)) {
      subCategoriesAmounts[userFrequencyType] = {};
    }
    if (!(volume in subCategoriesAmounts[userFrequencyType])) {
      subCategoriesAmounts[userFrequencyType][volume] = 1;
    } else {
      subCategoriesAmounts[userFrequencyType][volume] += 1;
    }
  });

  const totalSum = sourceFreqTypeCounts.reduce(
    (accumulator, category) => accumulator + category.count,
    0
  );

  return !countUserFrequencyType.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <>
      <Row justify="space-between">
        <Typography.Text className={styles.title}>
          High frequency, average frequency vs. low frequency users
        </Typography.Text>

        <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
          <Col>
            <ChartFilters
              value={{
                dataSource: countUserFrequencyType.dataSource,
                timeframe: countUserFrequencyType.timeframe,
                timeframeType: countUserFrequencyType.timeframeType,
              }}
              options={[
                { label: 'Incident', value: 'incidents' },
                { label: 'Request', value: 'requests' },
                { label: 'Survey', value: 'surveys' },
              ]}
              onChange={(value) => {
                getVisualByName(
                  VisualName.CountUserFrequencyType,
                  {
                    sourceFreqTypeCounts: [],
                    userFreqTypeCounts: [],
                  },
                  value
                );
              }}
            />
          </Col>

          <Col>
            <HelpPopover placement="bottomRight">
              <Img
                src="./images/help/high-avg-low-frequency-users.jpeg"
                alt="Description of High, average and low frequency users chart"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '850px',
                }}
              />
            </HelpPopover>
          </Col>
        </Row>
      </Row>
      <Row gutter={[8, 8]}>
        <Col md={24} lg={12}>
          <Box type="secondary" className={styles.flexBox}>
            {sourceFreqTypeCounts.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <>
                <div className={styles.switch}>
                  <span>Show subcategories for:</span>
                  <Select
                    value={selectedCategory}
                    options={categoriesNames.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    popupMatchSelectWidth={false}
                    placeholder="Select category"
                    onChange={(value) => setSelectedCategory(value)}
                    style={{ minWidth: 100 }}
                  />
                  <Popover
                    trigger="hover"
                    placement="bottom"
                    content={popupContent}
                  >
                    <QuestionCircleOutlined className={styles.helpIcon} />
                  </Popover>
                </div>
                <div className={styles.doughnut}>
                  <DoughnutChart
                    selected={selectedCategory}
                    totalCount={totalSum}
                    graphData={sourceFreqTypeCounts}
                  />
                  <div className={styles.note}>
                    <strong>Categories threshold:</strong>
                    <br />
                    - volume &gt; 25: # High Frequency
                    <br />
                    - 8 &lt; volume &lt;= 25 : # Average Frequency
                    <br />
                    - volume &lt;= 8: # Low Frequency
                    <br />
                  </div>
                </div>
              </>
            )}
          </Box>
        </Col>
        <Col md={24} lg={12}>
          <Box type="secondary" className={styles.flexBox}>
            {userFreqTypeCounts.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              selectedCategory && (
                <div>
                  <div className={styles.switch}>
                    Quantity of users and tickets for {selectedCategory}{' '}
                    category.
                  </div>
                  <BarChart
                    labels={Object.keys(
                      subCategoriesAmounts[selectedCategory]
                    ).sort((a: any, b: any) => a - b)}
                    data={Object.keys(subCategoriesAmounts[selectedCategory])
                      .sort((a: any, b: any) => a - b)
                      .reduce(
                        (obj: any, key: any) => ({
                          ...obj,
                          [key]: {
                            count: subCategoriesAmounts[selectedCategory][key],
                          },
                        }),
                        {}
                      )}
                    chartColors={secondary}
                    options={{
                      scales: {
                        x: {
                          title: {
                            display: true,
                            color: 'white',
                            text: `number of tickets for subcategory`,
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            color: 'white',
                            text: `number of accounts/persons`,
                          },
                        },
                      },
                    }}
                  />
                </div>
              )
            )}
          </Box>
        </Col>
      </Row>
    </>
  );
}

export default HighLowFrequencyUsersChart;
