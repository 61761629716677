import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Switch, Row, Col, Typography, Empty } from 'antd';
import WordCloud from 'react-d3-cloud';
import BarChart from '../BarChart/BarChart';
import {
  TicketsByCategory,
  TicketsByCategoryData,
  TopFrequencyWords,
} from '../../types/Visuals';
import { BAR_CHART_COLORS } from '../../constants/visuals';
import styles from './TicketsByCategoryChart.module.scss';
import Select from '../Select/Select';
import Box from '../Box/Box';
import { useDashboardContext } from '../../context/Dashboard.context';
import { useTicketsContext } from '../../context/Tickets.context';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';
import { getWordScale } from '../../utils/visual-utils';

const { primary, secondary } = BAR_CHART_COLORS;

function TicketsByCateogryChart() {
  const {
    visualStates: { ticketVolumesByCategory, topFrequencyWordsByCategory },
    summaryDateRange,
    getVisualByName,
  } = useDashboardContext();
  const { navigateToTickets } = useTicketsContext();

  const [chartData, setChartData] = useState<TicketsByCategory | null>(null);
  const [showFilteredCategoryData, setShowFilteredCategoryData] =
    useState(false);
  const [showFilteredSubcategoryData, setShowFilteredSubcategoryData] =
    useState(false);
  const [category, setCategory] = useState('');

  useEffect(() => {
    setChartData(ticketVolumesByCategory.data);
  }, [ticketVolumesByCategory]);

  const handleCategoryChange = (value: string) => {
    setCategory(value);
    getVisualByName<TopFrequencyWords[] | null>(
      VisualName.TopFrequencyWordsByCategory,
      null,
      {
        category: value,
        timeframeType: ticketVolumesByCategory.timeframeType,
        timeframe: ticketVolumesByCategory.timeframe,
        dataSource: ticketVolumesByCategory.dataSource,
      }
    );
  };

  const filterChartData = () => {
    if (chartData) {
      const filteredData = {
        categories: [] as Array<string>,
        data: {} as TicketsByCategoryData,
      };

      Object.keys(chartData.data).forEach((key) => {
        const counts = chartData.data[key];
        if (counts.percent > 1 && counts.percent < 50) {
          filteredData.categories.push(key);
          filteredData.data[key] = counts;
        }
      });
      return filteredData;
    }

    return null;
  };

  const handleCategoryClick = (
    selectedCategory: string,
    selectedSubcategory = ''
  ) => {
    // Navigate to Ticket Insights page and pre-populate filter and show results
    navigateToTickets({
      dateRange: summaryDateRange,
      cmdbCiMainCategory: selectedCategory,
      cmdbCiSubCategory: selectedSubcategory || null,
    });
  };

  const displayedData = showFilteredCategoryData
    ? filterChartData()
    : chartData;

  const getSubcategoryChart = () => {
    const labels: string[] = [];
    const data: { [key: string]: { count: number; percent: number } } = {};
    const graphData = displayedData!.data[category!];
    graphData.subcategories.forEach(({ name, count, percent }) => {
      if (showFilteredSubcategoryData) {
        if (percent > 1 && percent < 80) {
          labels.push(name);
          data[name] = {
            count,
            percent,
          };
        }
      } else {
        labels.push(name);
        data[name] = {
          count,
          percent,
        };
      }
    });
    return (
      category && (
        <>
          <Row gutter={[8, 8]}>
            <Col md={24} lg={18} className={styles.subInfo}>
              <span>
                <strong>Category: </strong>
                {category}
              </span>
              <span>
                <strong>Total count: </strong>
                {graphData.count}
              </span>
              <span>
                <strong>Total percentage: </strong>
                {graphData.percent.toFixed(2)}%
              </span>
            </Col>
            <Col md={24} lg={6} className={styles.switch}>
              <span>Filter out outlier data</span>
              <Switch
                checked={showFilteredSubcategoryData}
                onChange={(isChecked) =>
                  setShowFilteredSubcategoryData(isChecked)
                }
              />
            </Col>
          </Row>
          <BarChart
            labels={labels}
            data={data}
            chartColors={secondary}
            onBarClick={(dataIndex) =>
              handleCategoryClick(category, labels[dataIndex])
            }
          />
        </>
      )
    );
  };

  const getCategoryWordCloud = () =>
    topFrequencyWordsByCategory.data.topFrequencyWords.length === 0 ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No data to visualize"
      />
    ) : (
      <WordCloud
        data={topFrequencyWordsByCategory.data.topFrequencyWords}
        rotate={0}
        random={() => 0.5}
        height={250}
        fontSize={(word) =>
          Math.sqrt(word.value) *
          getWordScale(
            topFrequencyWordsByCategory.data.topFrequencyWords.length
          )
        }
        onWordClick={(_, word) => {
          navigateToTickets({
            dateRange: summaryDateRange,
            description: word.text,
          });
        }}
      />
    );

  return !ticketVolumesByCategory.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <>
      <Row justify="space-between">
        <Typography.Text className={styles.title}>
          Ticket volumes by categories
        </Typography.Text>

        <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
          <Col>
            <ChartFilters
              value={{
                dataSource: ticketVolumesByCategory.dataSource,
                timeframe: ticketVolumesByCategory.timeframe,
                timeframeType: ticketVolumesByCategory.timeframeType,
              }}
              options={[
                { label: 'Incident', value: 'incidents' },
                { label: 'Survey', value: 'surveys' },
              ]}
              onChange={(value) => {
                getVisualByName(
                  VisualName.TicketVolumesByCategory,
                  null,
                  value
                );
                setCategory('');
              }}
            />
          </Col>

          <Col>
            <HelpPopover placement="bottomRight">
              <Img
                src="./images/help/ticket-volumes-by-category.jpeg"
                alt="Description of Ticket volumes by categories chart"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '1000px',
                }}
              />
            </HelpPopover>
          </Col>
        </Row>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={24} lg={12}>
          <Box type="secondary" className={styles.flexBox}>
            {/* Category chart */}
            {!displayedData || Object.keys(displayedData.data).length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <>
                <div className={styles.switch}>
                  <span>Show subcategories for:</span>
                  <Select
                    value={category}
                    options={displayedData.categories.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    popupMatchSelectWidth={false}
                    placeholder="Select category"
                    onChange={handleCategoryChange}
                    style={{ minWidth: 100 }}
                  />
                  <span>Filter out outlier data</span>
                  <Switch
                    checked={showFilteredCategoryData}
                    onChange={(isChecked) => {
                      setShowFilteredCategoryData(isChecked);
                      setCategory('');
                      setShowFilteredSubcategoryData(false);
                    }}
                  />
                </div>
                <BarChart
                  labels={displayedData.categories}
                  data={displayedData.data}
                  chartColors={primary}
                  selected={category}
                  onBarClick={(dataIndex) =>
                    handleCategoryClick(displayedData.categories[dataIndex])
                  }
                />
              </>
            )}
          </Box>
        </Col>
        <Col md={24} lg={12}>
          <Box type="secondary" className={styles.flexBox}>
            {/* Subcategory chart */}
            {!displayedData || Object.keys(displayedData.data).length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              category && getSubcategoryChart()
            )}
          </Box>
        </Col>
      </Row>

      {/* Wordcloud chart */}
      {category &&
      displayedData &&
      Object.keys(displayedData.data).length !== 0 ? (
        <Row>
          <Typography.Text className={styles.title}>
            Most frequent phrases in category: <strong>{category}</strong>
          </Typography.Text>

          <Box type="secondary" className={styles.flexBox}>
            {/* Show loading */}
            {!topFrequencyWordsByCategory.isLoaded ? (
              <div className={styles.loading}>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                />
              </div>
            ) : (
              // Show chart
              getCategoryWordCloud()
            )}
          </Box>
        </Row>
      ) : null}
    </>
  );
}

export default TicketsByCateogryChart;
