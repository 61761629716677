import { Col, Row, Typography } from 'antd';
import { InteractionItemType, Transcript } from '../../types/Calls';
import { msToTime } from '../../utils/date-utils';
import { getTag } from '../Sentiment/Sentiment';
import styles from './CallTranscript.module.scss';

interface Props {
  transcript: Transcript;
  type: InteractionItemType;
}

function CallTranscript({ transcript, type }: Props) {
  return (
    <>
      {transcript.map((message) => (
        <div key={message.Id || message.Timestamp} className={styles.message}>
          <Row align="middle" gutter={8}>
            {type === 'chat' ? (
              // Chats are using `Timestamp`
              <>
                <Col>
                  <Typography.Text strong>
                    {message.ParticipantId === 'CUSTOMER'
                      ? 'USER'
                      : message.ParticipantId}
                  </Typography.Text>
                </Col>
                <Col>{msToTime(message.Timestamp)}</Col>
              </>
            ) : (
              // Calls are using `BeginOffsetMillis` and `EndOffsetMillis`
              <>
                <Col>
                  <Typography.Text strong>
                    {message.ParticipantId === 'CUSTOMER'
                      ? 'CALLER'
                      : message.ParticipantId}
                  </Typography.Text>
                </Col>
                <Col>
                  ({msToTime(message.BeginOffsetMillis)} -{' '}
                  {msToTime(message.EndOffsetMillis)})
                </Col>
              </>
            )}
            <Col>{getTag(message.Sentiment)}</Col>
          </Row>
          <div className={styles.messageText}>{message.Content}</div>
        </div>
      ))}
    </>
  );
}

export default CallTranscript;
