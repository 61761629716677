import { useState } from 'react';
import { Input } from 'antd';
import { PaginationProps } from '../../types/Pagination';
import styles from './Pagination.module.scss';
import debounce from '../../utils/form-utils';

function Pagination({
  pageSize = 10,
  currentPage,
  totalItems,
  onChange,
}: PaginationProps) {
  const [showError, setShowError] = useState(false);
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (value: string) => {
    const pageNumber = parseInt(value, 10);
    if (pageNumber && pageNumber > 0 && pageNumber <= totalPages) {
      setShowError(false);
      onChange(pageNumber);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className={styles.pagination}>
      <span>Showing: {totalItems} results</span>
      <div className={styles.pageSelector}>
        <button
          type="button"
          disabled={currentPage === 1}
          onClick={() => onChange(currentPage - 1)}
          className={styles.paginationButton}
        >
          &lt;
        </button>
        <p>{currentPage}</p>
        <button
          type="button"
          disabled={currentPage === totalPages}
          onClick={() => onChange(currentPage + 1)}
          className={styles.paginationButton}
        >
          &gt;
        </button>
      </div>
      <span className={styles.pageCounter}>
        Page{' '}
        <Input
          defaultValue={currentPage}
          onChange={debounce((e) => handlePageChange(e.target.value), 1000)}
          status={showError ? 'error' : ''}
        />{' '}
        of {totalPages}
      </span>
    </div>
  );
}

export default Pagination;
