import { ReactNode } from 'react';
import styles from './FormGroup.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

function FormGroup({ children = '', className = '' }: Props) {
  return <div className={`${styles.formGroup} ${className}`}>{children}</div>;
}

export default FormGroup;
