import { Col, Layout, Row, Space, Typography, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Doughnut } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import Box from '../../components/Box/Box';
import styles from './TicketInsights.module.scss';
import { useTicketsContext } from '../../context/Tickets.context';
import COLUMNS from '../../constants/table';
import TicketFilters from '../../components/TicketFilters/TicketFilters';
import DataTable from '../../components/DataTable/DataTable';
import { Insight, TicketsPayload } from '../../types/Tickets';
import { TicketFilter } from '../../types/Filters';
import TICKET_FILTER_DEFAULT from '../../constants/filter';
import HelpPopover from '../../components/HelpPopover/HelpPopover';
import Img from '../../components/Img/Img';

function TicketInsights() {
  const {
    totalCount,
    pageNumber,
    setPageNumber,
    tickets,
    getTickets,
    insights,
    getInsights,
    setFilter,
  } = useTicketsContext();
  const [searchParams] = useSearchParams();

  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const fetchTickets = async (
    payload: Partial<TicketsPayload>,
    filter?: TicketFilter
  ) => {
    setIsLoadingTickets(true);
    await getTickets(payload, filter);
    setIsLoadingTickets(false);
  };

  const fetchInsights = async (filter?: TicketFilter) => {
    setIsLoadingInsights(true);
    await getInsights(filter);
    setIsLoadingInsights(false);
  };

  // transform searchParams object into TicketFilter structure
  const queryFilter = useMemo<Partial<TicketFilter>>(() => {
    const keys = [...new Set(searchParams.keys())];
    const {
      dateRange,
      cmdbCiMainCategory,
      cmdbCiSubCategory,
      assignmentGroup,
      description,
    } = keys.reduce<{ [key: string]: string[] }>(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: searchParams.getAll(currentValue),
      }),
      {}
    );

    return {
      ...(dateRange && {
        dateRange: {
          start: dateRange[0],
          end: dateRange[1],
        },
      }),
      ...(cmdbCiMainCategory && { cmdbCiMainCategory: cmdbCiMainCategory[0] }),
      ...(cmdbCiSubCategory && { cmdbCiSubCategory: cmdbCiSubCategory[0] }),
      ...(assignmentGroup && { assignmentGroup }),
      ...(description && { description: description[0] }),
    };
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size) {
      // when query/search params are present
      const newFilter = { ...TICKET_FILTER_DEFAULT, ...queryFilter };
      setFilter(newFilter);
      fetchTickets({ pageNumber: 1 }, newFilter);
      fetchInsights(newFilter);
    } else if (tickets.length === 0) {
      // when page visited without any search/query params
      fetchTickets({ pageNumber: 1 });
      fetchInsights();
    }
  }, [queryFilter]);

  const getInsightsBox = (insight: Insight) => {
    const number = insight.value.toLocaleString();
    return (
      <Box className={styles.verticalArea}>
        <Typography.Title
          className={number.length > 7 ? styles.longNumber : undefined}
        >
          {number}
        </Typography.Title>
        <Typography.Text>{insight.label}</Typography.Text>
      </Box>
    );
  };

  const getInsightsGraph = () => {
    const doughnutColors = ['#178FFE', '#cccccc', '#ff004d'];
    const doughnutData = {
      labels: insights.graphData.map(({ label }) => label),
      datasets: [
        {
          borderWidth: 0,
          backgroundColor: doughnutColors,
          data: insights.graphData.map(({ value }) => value),
        },
      ],
      plugins: {
        display: false,
      },
    };
    return (
      <div className={styles.graphWrapper}>
        <Doughnut
          data={doughnutData}
          options={{
            maintainAspectRatio: false,
            rotation: -90,
            circumference: 180,
            layout: {
              padding: 16,
            },
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  color: 'white',
                  boxHeight: 8,
                  usePointStyle: true,
                },
              },
            },
          }}
        />
      </div>
    );
  };

  return (
    <Layout>
      <Space direction="vertical" size={24}>
        <Box>
          <Row gutter={8}>
            <Col span={7}>
              <Typography.Text className={styles.areaTitle}>
                Intro Text
              </Typography.Text>
            </Col>
            <Col span={11}>
              <Row justify="space-between">
                <Typography.Text className={styles.areaTitle}>
                  Output Box
                </Typography.Text>

                <HelpPopover>
                  <Img
                    src="./images/help/ticket-output-boxes.jpeg"
                    alt="Description of Output Boxes"
                    style={{
                      color: 'black',
                      width: '90vw',
                      maxWidth: '750px',
                    }}
                  />
                </HelpPopover>
              </Row>
            </Col>
            <Col span={6}>
              <Typography.Text className={styles.areaTitle}>
                Dynamic Visual Graph
              </Typography.Text>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={7}>
              <Box className={styles.colHeight} type="secondary">
                <div>
                  <Typography.Text strong>
                    This page is meant to allow users to create advanced queries
                    based on the prioritized filters determined by the User
                    Services team.
                  </Typography.Text>
                </div>
                <br />
                <div>
                  <Typography.Text>
                    Simply select the specified values within each filter and
                    press &apos;Search&apos; to receive your queried results.
                  </Typography.Text>
                </div>
              </Box>
            </Col>
            <Col span={11}>
              <Box type="secondary" className={styles.colHeight}>
                {isLoadingInsights ? (
                  <div className={styles.loading}>
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                    />
                  </div>
                ) : (
                  <Row gutter={[8, 8]} className={styles.colHeight}>
                    {insights.boxData.slice(0, 4).map((insight) => (
                      <Col md={12} lg={6} key={insight.label}>
                        {getInsightsBox(insight)}
                      </Col>
                    ))}
                  </Row>
                )}
              </Box>
            </Col>
            <Col span={6}>
              <Box type="secondary" className={styles.graphBox}>
                {getInsightsGraph()}
              </Box>
            </Col>
          </Row>
        </Box>

        {/* Filter */}
        <TicketFilters
          setIsLoading={setIsLoadingTickets}
          showAdditionalFilters={showAdditionalFilters}
          setShowAdditionalFilters={setShowAdditionalFilters}
          onSubmit={() => {
            setPageNumber(1);
            fetchTickets({ pageNumber: 1 });
            fetchInsights();
          }}
          totalCount={totalCount}
        />

        {/*   TODO: Equally space header columns  */}
        {isLoadingTickets ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
          </div>
        ) : (
          <DataTable
            dataSource={tickets}
            columns={COLUMNS}
            isFilterOpen={showAdditionalFilters}
            pagination={{
              currentPage: pageNumber,
              totalItems: totalCount,
              onChange: (newPage: number) => {
                setPageNumber(newPage);
                // get tickets data for currently selected page
                fetchTickets({ pageNumber: newPage });
              },
            }}
          />
        )}
      </Space>
    </Layout>
  );
}

export default TicketInsights;
