import { Link } from 'react-router-dom';
import { TicketDetails } from '../types/Tickets';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const formatAsSNOWLink = (text: string, record: TicketDetails) => {
  let baseLink =
    'https://seuprod.service-now.com/now/nav/ui/classic/params/target/';
  switch (record.requestType) {
    case 'Incident':
      baseLink = `${baseLink}incident.do%3Fsys_id%3D`;
      break;
    case 'Incident Survey':
      baseLink = `${baseLink}incident.do%3Fsys_id%3D`;
      break;
    case 'Requested Item':
      baseLink = `${baseLink}sc_task.do%3Fsys_id%3D`;
      break;
    case 'Requested Item Survey':
      baseLink = `${baseLink}sc_task.do%3Fsys_id%3D`;
      break;
    default:
  }

  baseLink = `${baseLink}${record.systemID}`;
  return (
    <Link
      to={baseLink}
      target="_blank"
      style={{
        color: 'white',
        fontWeight: '700',
        textDecoration: 'underline',
      }}
    >
      {text}
    </Link>
  );
};

export { DATE_FORMAT, DATE_TIME_FORMAT, formatAsSNOWLink };
