import { msToTime } from '../../utils/date-utils';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import styles from './CallSegmentShare.module.scss';

interface Props {
  segmentation: {
    source: string;
    count: string | number;
  }[];
}

function CallSegmentShare({ segmentation }: Props) {
  return (
    <div className={styles.wrapper}>
      <DoughnutChart
        graphData={segmentation}
        totalCount={0}
        legend={{
          position: 'bottom',
        }}
        labelFormatter={({ source, count }) =>
          `${source} (${msToTime(Number(count))})`
        }
        tooltipFormatter={({ parsed }) => msToTime(parsed)}
        height={250}
      />
    </div>
  );
}

export default CallSegmentShare;
