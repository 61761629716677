import { ReactNode, useEffect, useState, forwardRef } from 'react';
import Color from '../../types/enums/Colors';
import styles from './Box.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: { [key: string]: string };
  type?: 'primary' | 'secondary';
}

const Box = forwardRef(
  (
    { children = '', className = '', type = 'primary', style = {} }: Props,
    ref
  ) => {
    const [backgroundColor, setBacgroundColor] = useState(Color.BgPrimary);
    const [borderRadius, setBorderRadius] = useState(0);

    useEffect(() => {
      if (type !== 'primary') {
        setBacgroundColor(Color.BgSecondary);
        setBorderRadius(8);
      }
    }, []);

    return (
      <div
        className={`${styles.box} ${className}`}
        style={{ ...style, backgroundColor, borderRadius }}
        ref={ref as React.RefObject<HTMLDivElement>}
      >
        {children}
      </div>
    );
  }
);

export default Box;
