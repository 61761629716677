import { Row, Col, Typography, Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WordCloud from 'react-d3-cloud';
import Box from '../Box/Box';
import styles from './TopFrequencyWords.module.scss';
import { useDashboardContext } from '../../context/Dashboard.context';
import { useTicketsContext } from '../../context/Tickets.context';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';
import { getWordScale } from '../../utils/visual-utils';

function TopFrequencyWords() {
  const {
    visualStates: { topFrequencyWords },
    summaryDateRange,
    getVisualByName,
  } = useDashboardContext();
  const { navigateToTickets } = useTicketsContext();

  return !topFrequencyWords.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    topFrequencyWords.data && (
      <Row gutter={8}>
        <Col span={24}>
          <Row justify="space-between">
            <Typography className={styles.tileTitle}>
              Most frequent phrases
            </Typography>
            <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
              <Col>
                <ChartFilters
                  value={{
                    dataSource: topFrequencyWords.dataSource,
                    timeframe: topFrequencyWords.timeframe,
                    timeframeType: topFrequencyWords.timeframeType,
                  }}
                  options={[
                    { label: 'Incident', value: 'incidents' },
                    { label: 'Survey', value: 'surveys' },
                  ]}
                  onChange={(value) => {
                    getVisualByName(VisualName.TopFrequencyWords, [], value);
                  }}
                />
              </Col>
              <Col>
                <HelpPopover placement="bottomRight">
                  <Img
                    src="./images/help/most-frequent-phrases.jpeg"
                    alt="Description of Most frequent phrases chart"
                    style={{
                      color: 'black',
                      width: '90vw',
                      maxWidth: '1000px',
                    }}
                  />
                </HelpPopover>
              </Col>
            </Row>
          </Row>
          <Box type="secondary">
            {topFrequencyWords.data.topFrequencyWords.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <WordCloud
                data={topFrequencyWords.data.topFrequencyWords}
                rotate={0}
                random={() => 0.5}
                height={350}
                fontSize={(word) =>
                  Math.sqrt(word.value) *
                  getWordScale(topFrequencyWords.data.topFrequencyWords.length)
                }
                onWordClick={(_, word) => {
                  navigateToTickets({
                    dateRange: summaryDateRange,
                    description: word.text,
                  });
                }}
              />
            )}
          </Box>
        </Col>
      </Row>
    )
  );
}

export default TopFrequencyWords;
