import { Doughnut } from 'react-chartjs-2';
import { LegendOptions, TooltipItem } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { BAR_CHART_COLORS, LINE_CHART_COLORS } from '../../constants/visuals';
import { hexToRgb } from '../../utils/visual-utils';
import { DoughnutChartDataItem } from '../../types/Visuals';

interface DoughnutChartProps {
  graphData?: DoughnutChartDataItem[];
  totalCount: number;
  selected?: string;
  legend?: _DeepPartialObject<LegendOptions<'doughnut'>>;
  height?: string | number;
  width?: string | number;
  tooltipFormatter?: (data: TooltipItem<'doughnut'>) => string;
  labelFormatter?: (dataItem: DoughnutChartDataItem) => string;
}

function DoughnutChart({
  graphData,
  totalCount,
  selected,
  legend = {},
  height,
  width,
  tooltipFormatter = (data: TooltipItem<'doughnut'>) => {
    const percentage = ((data.parsed / totalCount) * 100).toFixed(2);
    return `${percentage}%`;
  },
  labelFormatter = ({ source, count }) => `${source} (${count})`,
}: DoughnutChartProps) {
  if (!graphData) return null;

  const selectedColor = BAR_CHART_COLORS.secondary[0];
  let colors = LINE_CHART_COLORS;
  if (selected) {
    colors = BAR_CHART_COLORS.primary;
  }

  const doughnutData = {
    labels: graphData.map(labelFormatter),
    datasets: [
      {
        borderWidth: 1,
        backgroundColor: graphData.map((item, index) =>
          item?.source === selected
            ? hexToRgb(selectedColor, 0.5)
            : hexToRgb(colors[index % colors.length], 0.5)
        ),
        borderColor: graphData.map((item, index) =>
          item?.source === selected
            ? selectedColor
            : colors[index % colors.length]
        ),
        data: graphData.map(({ count }) => count),
      },
    ],
  };

  return (
    <div style={{ width: '100%' }}>
      <Doughnut
        data={doughnutData}
        options={{
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                title: (data) => data[0].label.split(' ')[0],
                label: tooltipFormatter,
              },
            },
            legend: {
              position: 'left',
              labels: {
                color: 'white',
                boxHeight: 8,
                usePointStyle: true,
              },
              ...legend,
            },
          },
        }}
        height={height}
        width={width}
      />
    </div>
  );
}

export default DoughnutChart;
