import axios from '../axios';
import {
  CallByIdResponse,
  CallFilterOptionsResponse,
  CallsPayload,
  CallsResponse,
  Calls,
} from '../types/Calls';
import { CallFilterOptions } from '../types/Filters';
import removeNullUndefined from '../utils/obj-utils';

const callsService = {
  getCallFilterOptions: async (): Promise<CallFilterOptions> => {
    try {
      const { data }: CallFilterOptionsResponse = await axios.get(
        '/calls/filter-options'
      );

      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        tags: [],
        agentList: [],
        callDurationRanges: [],
        sentiments: [],
        interactionTypes: [],
      };
    }
  },
  getCallById: async (id: string) => {
    try {
      const { data }: CallByIdResponse = await axios.get(`/calls/${id}`);

      const { body, statusCode } = data.basicsResponse;
      if (statusCode !== 200) {
        return null;
      }
      return body;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  getCalls: async (payload: Partial<CallsPayload>): Promise<Calls> => {
    const filteredPayload = removeNullUndefined(payload);

    try {
      const { data }: CallsResponse = await axios.post(
        '/calls',
        filteredPayload
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        calls: [],
        totalCount: 0,
      };
    }
  },
};

export default callsService;
