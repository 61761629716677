import { Row, Tag } from 'antd';
import Color from '../../types/enums/Colors';

import styles from './CallTags.module.scss';

interface CallTagsProps {
  tags: Array<string>;
}

function CallTags({ tags }: CallTagsProps) {
  return (
    <Row>
      {tags && tags.length > 0 ? (
        tags.map((item) => (
          <div className={styles.tagContainer} key={`${item}`}>
            <Tag className={styles.tag} color={Color.Primary}>
              {item}
            </Tag>
          </div>
        ))
      ) : (
        <div>No tags available</div>
      )}
    </Row>
  );
}

export default CallTags;
