import axios from '../axios';
import { Option, SurveyFilterOptions } from '../types/Filters';
import {
  SurveyFilterOptionsResponse,
  SurveyResponse,
  SurveysPayload,
  Surveys,
  SearchSurveyFilterOptionsResponse,
} from '../types/Surveys';
import removeNullUndefined from '../utils/obj-utils';

const surveysService = {
  getSurveyFilterOptions: async (): Promise<SurveyFilterOptions> => {
    try {
      const { data }: SurveyFilterOptionsResponse = await axios.get(
        '/surveys/filter-options'
      );

      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        requestTypes: [],
        callerIds: [],
        callerDepts: [],
        assignmentGroups: [],
        callerManagers: [],
        sempraDirs: [],
        questions: [],
        scaledValues: [],
      };
    }
  },
  searchSurveyFilterOptions: async (
    fieldName: string,
    filterName: string,
    searchString: string
  ): Promise<Option[]> => {
    try {
      const { data }: SearchSurveyFilterOptionsResponse = await axios.post(
        `/surveys/filter-options/${fieldName}`,
        {
          filterName,
          searchString,
        }
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  getSurveys: async (payload: Partial<SurveysPayload>): Promise<Surveys> => {
    const filteredPayload = removeNullUndefined(payload);

    try {
      const { data }: SurveyResponse = await axios.post(
        '/surveys',
        filteredPayload
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        surveys: [],
        totalCount: 0,
      };
    }
  },
  getSurveysCsv: async (payload: Partial<SurveysPayload>): Promise<Blob> => {
    try {
      const file = await axios.post('/surveys/download', payload);

      return file.data;
    } catch (e) {
      console.error(e);
      return new Blob();
    }
  },
};

export default surveysService;
