import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { SummaryOverallSentiment } from '../../types/Visuals';
import styles from './SentimentChart.module.scss';

interface SentimentChartProps {
  sentimentInput: SummaryOverallSentiment;
}

function SummaryChart({ sentimentInput }: SentimentChartProps) {
  const [sentiment, setSentiment] = useState(sentimentInput);
  const [shouldShowChart, setShouldShowChart] = useState(false);

  useEffect(() => {
    setSentiment(sentimentInput);
    if (
      sentimentInput &&
      sentimentInput.positiveSentimentCount &&
      sentimentInput.neutralSentimentCount &&
      sentimentInput.negativeSentimentCount
    ) {
      setShouldShowChart(true);
    } else {
      setShouldShowChart(false);
    }
  }, [sentimentInput]);

  return shouldShowChart ? (
    <div className={styles.chartContainer}>
      {sentiment.positiveSentimentCount > 0 && (
        <div
          className={`${styles.chartBar} ${styles.positive}`}
          style={{
            flex: `${sentiment.positiveSentimentCount}`,
          }}
        >
          <Typography.Text className={styles.innerText}>
            {Math.round(
              (sentiment.positiveSentimentCount * 100) /
                sentiment.totalSentimentCount
            )}
          </Typography.Text>
        </div>
      )}
      {sentiment.neutralSentimentCount > 0 && (
        <div
          className={`${styles.chartBar} ${styles.neutral}`}
          style={{
            flex: `${sentiment.neutralSentimentCount}`,
          }}
        >
          <Typography.Text className={styles.innerText}>
            {Math.round(
              (sentiment.neutralSentimentCount * 100) /
                sentiment.totalSentimentCount
            )}
          </Typography.Text>
        </div>
      )}
      {sentiment.negativeSentimentCount > 0 && (
        <div
          className={`${styles.chartBar} ${styles.negative}`}
          style={{
            flex: `${sentiment.negativeSentimentCount}`,
          }}
        >
          <Typography.Text className={styles.innerText}>
            {Math.round(
              (sentiment.negativeSentimentCount * 100) /
                sentiment.totalSentimentCount
            )}
          </Typography.Text>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.chartContainer}>
      <div className={`${styles.chartBar} ${styles.nan}`}>
        <Typography.Text className={styles.innerText}>0</Typography.Text>
      </div>
    </div>
  );
}

export default SummaryChart;
