import { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { MinusOutlined } from '@ant-design/icons';

import styles from './DateSelector.module.scss';
import { DATE_FORMAT } from '../../constants/formatting';

const { RangePicker } = DatePicker;

interface DateSelectorProps {
  onDateRangeChange: Function;
  width?: number;
  defaultValue?: {
    start: string;
    end: string;
  } | null;
  disabled?: boolean;
}

interface DateSelectorState {
  start: Dayjs | null;
  end: Dayjs | null;
}

type RangeValue = [Dayjs | null, Dayjs | null] | null;

function DateSelector({
  onDateRangeChange,
  width,
  defaultValue,
  disabled,
}: DateSelectorProps) {
  const [date, setDate] = useState<DateSelectorState | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setDate({
        start: dayjs(defaultValue.start),
        end: dayjs(defaultValue.end),
      });
    } else {
      setDate(null);
    }
  }, [defaultValue]);

  const handleDateSelect = (dates: RangeValue) => {
    if (dates) {
      setDate({ start: dates[0], end: dates[1] });
    } else {
      setDate({ start: null, end: null });
    }
    onDateRangeChange(dates);
  };

  const disabledDates = (current: Dayjs) =>
    current &&
    (current < dayjs('2020-01-01') || current > dayjs().subtract(1, 'day'));
  return (
    <RangePicker
      style={{ width }}
      className={styles.datePicker}
      format={DATE_FORMAT}
      value={date && [date.start, date.end]}
      onChange={(value) => handleDateSelect(value)}
      allowClear
      separator={<MinusOutlined />}
      disabledDate={disabledDates}
      popupClassName={styles.calendar}
      disabled={disabled}
    />
  );
}

export default DateSelector;
