import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DashboardContext } from '../types/Context';
import {
  Summary,
  SummaryGraph,
  SummaryOutliers,
  SummaryTimeframe,
  TicketsByCategory,
  TicketTrendsByChannel,
  CountUserFrequencyType,
  UserPopulation,
  IncidentsTicketsBySegment,
  AggregatedIncidentsTicketsBySegment,
  TopFrequencyWords,
  VisualFilters,
  VisualsStates,
  TicketResTimes,
} from '../types/Visuals';
import visualsService from '../services/visuals.service';
import { DateRange } from '../types/Filters';
import VisualName from '../types/enums/Visuals';

const Context = createContext<DashboardContext>({} as DashboardContext);

export const useDashboardContext = () => useContext(Context);

interface Props {
  children: ReactNode;
}

export function DashboardProvider({ children }: Props) {
  const [summaryCounts, setSummaryCounts] = useState<Summary>({});
  const [summaryGraph, setSummaryGraph] = useState<SummaryGraph>({
    default: {
      opened: [],
      resolved: [],
    },
    custom: {
      opened: [],
      resolved: [],
    },
  });
  const [summaryOutliers, setSummaryOutliers] = useState<SummaryOutliers>({});
  const [summaryTimeframe, setSummaryTimeframe] =
    useState<SummaryTimeframe>('30-day');
  const [summaryDateRange, setSummaryDateRange] = useState<DateRange>(null);
  const [visualStates, setVisualStates] = useState<VisualsStates>({
    ticketVolumesByCategory: {
      data: null,
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    ticketResolutionTimes: {
      data: {
        ticketResTimes: [],
      },
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    ticketTrendsByChannel: {
      data: null,
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    countUserFrequencyType: {
      data: {
        sourceFreqTypeCounts: [],
        userFreqTypeCounts: [],
      },
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    userPopulation: {
      data: null,
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    incidentsTicketsBySegment: {
      data: null,
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    aggregatedIncidentsTicketsBySegment: {
      data: {
        legendData: [],
        graphData: [],
      },
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    topFrequencyWords: {
      data: {
        topFrequencyWords: [],
      },
      isLoaded: false,
      timeframeType: undefined,
      timeframe: undefined,
      dataSource: 'incidents',
    },
    topFrequencyWordsByCategory: {
      data: {
        topFrequencyWords: [],
      },
      isLoaded: false,
    },
  });

  const getSummary = async (dates: DateRange) => {
    const { summary, graph, outliers } = await visualsService.getSummary(dates);

    setSummaryCounts(summary);
    setSummaryGraph(graph);
    setSummaryOutliers(outliers);
    return { summary, graph, outliers };
  };

  async function getVisualAndSetState<T>(
    visualName: VisualName,
    responseOnError: T,
    filters?: VisualFilters
  ) {
    // Update state - set loading for chart we fetching data for
    setVisualStates((prevState) => ({
      ...prevState,
      [visualName]: {
        ...prevState[visualName],
        isLoaded: false,
      },
    }));

    // Send api request
    const response = await visualsService.getVisual<T>(
      visualName,
      responseOnError,
      filters
    );

    // Update state - unset loading and update chart data
    setVisualStates((prevState) => ({
      ...prevState,
      [visualName]: {
        ...prevState[visualName],
        data: response,
        isLoaded: true,
        ...(filters && filters.timeframeType
          ? { timeframeType: filters.timeframeType }
          : {}),
        ...(filters && filters.timeframe
          ? { timeframe: filters.timeframe }
          : {}),
        ...(filters && filters.dataSource
          ? { dataSource: filters.dataSource }
          : {}),
      },
    }));

    return response;
  }

  const getVisuals = async () => {
    const defaultFilter: VisualFilters = {
      dataSource: 'incidents',
      timeframeType: 'yearly',
      timeframe: dayjs().format('YYYY'),
    };

    // define promises to fetch all visuals
    const visualPromises = [
      () =>
        getVisualAndSetState<TicketsByCategory | null>(
          VisualName.TicketVolumesByCategory,
          null,
          defaultFilter
        ),
      () =>
        getVisualAndSetState<TicketResTimes>(
          VisualName.TicketResolutionTimes,
          {
            ticketResTimes: [],
          },
          defaultFilter
        ),
      () =>
        getVisualAndSetState<TicketTrendsByChannel | null>(
          VisualName.TicketTrendsByChannel,
          null,
          defaultFilter
        ),
      () =>
        getVisualAndSetState<CountUserFrequencyType>(
          VisualName.CountUserFrequencyType,
          {
            sourceFreqTypeCounts: [],
            userFreqTypeCounts: [],
          },
          defaultFilter
        ),
      () =>
        getVisualAndSetState<UserPopulation | null>(
          VisualName.UserPopulation,
          null,
          defaultFilter
        ),
      () =>
        getVisualAndSetState<IncidentsTicketsBySegment | null>(
          VisualName.IncidentsTicketsBySegment,
          null,
          defaultFilter
        ),
      () =>
        getVisualAndSetState<AggregatedIncidentsTicketsBySegment>(
          VisualName.AggregatedIncidentsTicketsBySegment,
          {
            legendData: [],
            graphData: [],
          },
          defaultFilter
        ),
      () =>
        getVisualAndSetState<TopFrequencyWords>(
          VisualName.TopFrequencyWords,
          {
            topFrequencyWords: [],
          },
          defaultFilter
        ),
    ];
    // execute all promises
    await Promise.all(visualPromises.map((visualPromise) => visualPromise()));
  };

  async function getVisualByName<T>(
    visualName: VisualName,
    responseOnError: T,
    filters: VisualFilters
  ) {
    const response = await getVisualAndSetState<T>(
      visualName,
      responseOnError,
      filters
    );
    return response;
  }

  const contextValue = useMemo<DashboardContext>(
    () => ({
      summaryCounts,
      summaryGraph,
      summaryOutliers,
      getSummary,
      summaryTimeframe,
      setSummaryTimeframe,
      summaryDateRange,
      setSummaryDateRange,
      visualStates,
      getVisuals,
      getVisualByName,
    }),
    [
      summaryCounts,
      summaryGraph,
      summaryOutliers,
      getSummary,
      summaryTimeframe,
      setSummaryTimeframe,
      summaryDateRange,
      setSummaryDateRange,
      visualStates,
      getVisuals,
      getVisualByName,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
