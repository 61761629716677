import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { SurveyDetails, SurveysPayload } from '../types/Surveys';
import { SurveysContext } from '../types/Context';
import surveysService from '../services/surveys.service';
import { DateRange, SurveyFilter, SurveyFilterOptions } from '../types/Filters';
import { SURVEY_FILTER_DEFAULT } from '../constants/filter';

interface Props {
  children: ReactNode;
}

const Context = createContext<SurveysContext>({} as SurveysContext);

export const useSurveysContext = () => useContext(Context);

export function SurveysProvider({ children }: Props) {
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [surveys, setSurveys] = useState<SurveyDetails[]>([]);
  const [filter, setFilter] = useState<SurveyFilter>(SURVEY_FILTER_DEFAULT);
  const [filterOptions, setFilterOptions] = useState<SurveyFilterOptions>({
    requestTypes: [],
    callerIds: [],
    callerDepts: [],
    assignmentGroups: [],
    callerManagers: [],
    sempraDirs: [],
    questions: [],
    scaledValues: [],
  });
  const [hasLoadedOptions, setHasLoadedOptions] = useState(false);

  const getSurveys = async (
    payload: Partial<SurveysPayload>,
    surveyFilter = filter
  ) => {
    const response = await surveysService.getSurveys({
      ...payload,
      ...surveyFilter,
    });
    setSurveys(response.surveys);
    setTotalCount(response.totalCount);
    return response;
  };

  const navigateToSurveys = async (surveyFilter: Partial<SurveyFilter>) => {
    // get params object, but only if values are defined (do not include undefined or empty values)
    const paramsObj = Object.entries(surveyFilter).reduce<{
      [key: string]: string | string[];
    }>(
      (obj, [key, value]) => ({
        ...obj,
        ...(value && { [key]: value }),
        // if date range, then convert value from { start, end } object to [start, end] array
        ...(key === 'dateRange' && {
          [key]: [(value as DateRange)!.start, (value as DateRange)!.end],
        }),
      }),
      {}
    );
    const searchParams = `${createSearchParams(paramsObj)}`;
    window.open(`/insights/survey-insights?${searchParams} `, '_blank');
  };

  const getSurveysCsv = async (payload: Partial<SurveysPayload>) => {
    const response = await surveysService.getSurveysCsv({
      ...payload,
      ...filter,
      isDownload: true,
    });
    return response;
  };

  const getFilterOptions = async () => {
    const options = await surveysService.getSurveyFilterOptions();
    setFilterOptions(options);
    setHasLoadedOptions(true);
    return options;
  };

  const searchFilterOptions = async (
    fieldName: string,
    filterName: string,
    searchString: string
  ) => {
    const data = await surveysService.searchSurveyFilterOptions(
      fieldName,
      filterName,
      searchString
    );

    setFilterOptions({
      ...filterOptions,
      [filterName]: data,
    });
    return data;
  };

  const contextValue = useMemo<SurveysContext>(
    () => ({
      totalCount,
      pageNumber,
      setPageNumber,
      surveys,
      getSurveys,
      getSurveysCsv,
      filter,
      setFilter,
      filterOptions,
      getFilterOptions,
      hasLoadedOptions,
      searchFilterOptions,
      navigateToSurveys,
    }),
    [
      totalCount,
      pageNumber,
      setPageNumber,
      surveys,
      getSurveys,
      getSurveysCsv,
      filter,
      setFilter,
      filterOptions,
      getFilterOptions,
      hasLoadedOptions,
      searchFilterOptions,
      navigateToSurveys,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
