enum VisualName {
  TicketVolumesByCategory = 'ticketVolumesByCategory',
  TicketResolutionTimes = 'ticketResolutionTimes',
  TicketTrendsByChannel = 'ticketTrendsByChannel',
  UserPopulation = 'userPopulation',
  IncidentsTicketsBySegment = 'incidentsTicketsBySegment',
  AggregatedIncidentsTicketsBySegment = 'aggregatedIncidentsTicketsBySegment',
  CountUserFrequencyType = 'countUserFrequencyType',
  TopFrequencyWords = 'topFrequencyWords',
  TopFrequencyWordsByCategory = 'topFrequencyWordsByCategory',
}

export default VisualName;
