import { ThemeConfig, theme } from 'antd';
import Color from '../types/enums/Colors';

const appTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: Color.Primary,
    colorText: Color.Text,
    colorBgBase: Color.BgSecondary,
    fontFamily: 'Interstate',
    colorSplit: Color.Divider,
  },
  components: {
    Menu: {
      colorPrimary: Color.BgSecondary,
    },
    Table: {
      colorBorderSecondary: Color.BgSecondary,
    },
    Tag: {
      colorSuccess: Color.Success,
      colorSuccessBg: Color.BgSuccess,
      colorSuccessBorder: 'transparent',
      colorWarning: Color.Neutral,
      colorWarningBg: Color.BgNeutral,
      colorWarningBorder: 'transparent',
      colorError: Color.Error,
      colorErrorBg: Color.BgError,
      colorErrorBorder: 'transparent',
    },
    Switch: {
      colorPrimary: Color.Primary,
    },
    Button: {
      colorPrimary: Color.Primary,
      borderRadius: 4,
    },
    Spin: {
      colorPrimary: Color.Primary,
    },
    Radio: {
      colorPrimary: Color.Primary,
    },
    Slider: {
      colorPrimaryBorder: Color.Primary,
      colorPrimaryBorderHover: Color.Primary,
      colorPrimary: Color.Primary,
    },
  },
};

export default appTheme;
