import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Spin, Switch, Row, Col, Typography, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BAR_CHART_COLORS, LINE_CHART_COLORS } from '../../constants/visuals';
import { useDashboardContext } from '../../context/Dashboard.context';
import styles from './UserPopulationCharts.module.scss';
import { UserPopulation } from '../../types/Visuals';
import Box from '../Box/Box';
import { hexToRgb } from '../../utils/visual-utils';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

const renderUniqueUsersChart = (
  visuals: UserPopulation,
  showDetails: boolean
) => {
  const legendOptions = showDetails
    ? {
        labels: {
          color: 'white',
          boxHeight: 8,
          usePointStyle: true,
        },
      }
    : { display: false };
  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: legendOptions,
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
        title: {
          display: true,
          color: 'white',
          text: `Unique users`,
        },
      },
    },
  };

  const labels: string[] = [];
  const datasets = [];

  if (showDetails) {
    const data = visuals.detailed;
    const categories: string[] = [];
    data.forEach((item) => {
      if (!labels.some((label) => label === item.date)) {
        labels.push(item.date);
      }

      if (!categories.some((category) => category === item.source)) {
        categories.push(item.source);
      }
    });

    categories.forEach((category, index) => {
      datasets.push({
        label: category,
        backgroundColor: hexToRgb(LINE_CHART_COLORS[index], 0.6),
        data: labels.map((label) => {
          const item = data.find(
            ({ source, date }) => source === category && date === label
          );
          return item ? item.count : 0;
        }),
      });
    });
  } else {
    const data = visuals.grouped;
    const dataset = {
      label: 'Unique Users',
      backgroundColor: BAR_CHART_COLORS.primary,
      data: [] as string[],
    };
    data.forEach((item) => {
      labels.push(item.date);
      dataset.data.push(item.count);
    });
    datasets.push(dataset);
  }

  const chartData = {
    labels,
    datasets,
  };

  return <Bar options={options} data={chartData} />;
};

const renderTicketVolumesChart = (visuals: UserPopulation) => {
  const data = visuals.individual;

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
    },
  };

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'Tickets per User',
        backgroundColor: BAR_CHART_COLORS.primary,
        data: Object.values(data),
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
};

const getTotalTicketCount = (data: UserPopulation | null) => {
  let totalCount = 0;
  if (!data) return totalCount;

  totalCount = Object.values(data.individual).reduce(
    (sum, value) => sum + value,
    0
  );
  return totalCount;
};

function UserPopulationCharts() {
  const [showDetailedChart, setShowDetailedChart] = useState(true);
  const {
    visualStates: { userPopulation },
    getVisualByName,
  } = useDashboardContext();

  return !userPopulation.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <Row gutter={[8, 8]}>
      <Col md={24} lg={12}>
        <Row justify="space-between" style={{ paddingBottom: 8 }}>
          <Typography.Text className={styles.tileTitle}>
            Unique Users
          </Typography.Text>

          {showDetailedChart && (
            <HelpPopover>
              <Img
                src="./images/help/unique-users-1.jpeg"
                alt="Description of Unique Users split by source chart"
                style={{
                  color: 'black',
                  width: '90vw',
                  maxWidth: '800px',
                }}
              />
            </HelpPopover>
          )}
        </Row>

        <Box type="secondary">
          <div className={styles.flexEnd}>
            <Typography.Text>Split by source</Typography.Text>
            <Switch
              checked={showDetailedChart}
              onChange={(isChecked) => setShowDetailedChart(isChecked)}
            />
          </div>
          {!userPopulation.data || userPopulation.data.grouped.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            <div>
              {renderUniqueUsersChart(userPopulation.data, showDetailedChart)}
            </div>
          )}
        </Box>
      </Col>
      <Col md={24} lg={12}>
        <Row justify="space-between">
          <Typography.Text className={styles.tileTitle}>
            Ticket volumes per User
          </Typography.Text>

          <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
            <Col>
              <ChartFilters
                value={{
                  dataSource: userPopulation.dataSource,
                  timeframe: userPopulation.timeframe,
                  timeframeType: userPopulation.timeframeType,
                }}
                options={[
                  { label: 'Incident', value: 'incidents' },
                  { label: 'Requests', value: 'requests' },
                ]}
                onChange={(value) => {
                  getVisualByName(VisualName.UserPopulation, null, value);
                }}
              />
            </Col>

            <Col>
              <HelpPopover placement="bottomRight">
                <Img
                  src="./images/help/unique-users-2.jpeg"
                  alt="Description of Unique Users chart"
                  style={{
                    color: 'black',
                    width: '90vw',
                    maxWidth: '1000px',
                  }}
                />
              </HelpPopover>
            </Col>
          </Row>
        </Row>

        <Box type="secondary">
          <div className={styles.flexEnd}>
            <Typography.Text>
              Total ticket count: {getTotalTicketCount(userPopulation.data)}
            </Typography.Text>
          </div>
          {!userPopulation.data || userPopulation.data.grouped.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            renderTicketVolumesChart(userPopulation.data)
          )}
        </Box>
      </Col>
    </Row>
  );
}

export default UserPopulationCharts;
