import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Typography } from 'antd';
import CallSummary from '../CallSummary/CallSummary';
import CallTags from '../CallTags/CallTags';
import Box from '../Box/Box';
import styles from './CallList.module.scss';
import Color from '../../types/enums/Colors';
import { CallDetails } from '../../types/Calls';
import { formatDateTime, msToTime } from '../../utils/date-utils';
import INTERACTION_ITEM_MAPPING from '../../constants/mapping';

interface CallListProps {
  callList: Array<CallDetails>;
  isLoading?: boolean;
}

function CallList({ callList, isLoading = false }: CallListProps) {
  const navigate = useNavigate();

  const handleItemSelect = (callId: string) =>
    navigate(`/interaction-sentiment/${callId}`);

  return isLoading ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <div className={styles.callList}>
      {callList.length === 0 ? (
        <Box>
          <Row className={styles.tileTitle} gutter={8}>
            Search Results
          </Row>
          <Box type="secondary" className={styles.noResultsMessage}>
            <div>
              <Typography.Title className={styles.heading}>
                0 search results found{' '}
              </Typography.Title>
              <Typography.Text>
                Please try:
                <li>Searching again using different keywords</li>
                <li>Removing your filters</li>
              </Typography.Text>
            </div>
          </Box>
        </Box>
      ) : (
        callList.map((item) => {
          const { type } = item;
          return (
            <Row
              key={item.callID}
              gutter={8}
              className={styles.callDetail}
              style={{ backgroundColor: Color.BgPrimary }}
              onClick={() => handleItemSelect(item.callID)}
            >
              <Col className={styles.segment} lg={8}>
                <span className={styles.heading}>
                  {INTERACTION_ITEM_MAPPING[type]} information
                </span>
                <Box className={styles.details} type="secondary">
                  {type === 'chat' ? (
                    <Row>
                      <Col lg={12} className={styles.twoCols}>
                        <span className={styles.heading}>
                          Start Date & Time
                        </span>
                        <span>{formatDateTime(item.startDate)}</span>
                        <span className={styles.heading}>End Date & Time</span>
                        <span>{formatDateTime(item.endDate)}</span>
                        <span className={styles.heading}>Total Chat Time</span>
                        <span>{msToTime(item.duration)}</span>
                      </Col>

                      <Col lg={12} className={styles.twoCols}>
                        <span className={styles.heading}>
                          Average Agent Response Time
                        </span>
                        <span>{msToTime(item.agentResponseTime)}</span>
                        <span className={styles.heading}>Ticket ID</span>
                        <span>{item.contactID}</span>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg={12} className={styles.twoCols}>
                        <span className={styles.heading}>Date & Time</span>
                        <span>{formatDateTime(item.startDate)}</span>
                        <span className={styles.heading}>Duration</span>
                        <span>{msToTime(item.duration)}</span>
                        <span className={styles.heading}>No-Talk Time</span>
                        <span>{msToTime(item.noTalkTime)}</span>
                      </Col>

                      <Col lg={12} className={styles.twoCols}>
                        <span className={styles.heading}>Interruptions</span>
                        <span>{item.interruptions}</span>
                        <span className={styles.heading}>Contact ID</span>
                        <span>{item.contactID}</span>
                      </Col>
                    </Row>
                  )}
                </Box>
              </Col>
              <Col className={styles.segment} lg={8}>
                <span className={styles.heading}>Summary</span>
                <Box className={styles.details} type="secondary">
                  <CallSummary {...item} />
                </Box>
              </Col>
              <Col className={styles.segment} lg={8}>
                <span className={styles.heading}>Tags</span>
                <Box className={styles.details} type="secondary">
                  <CallTags tags={item.tags || []} />
                </Box>
              </Col>
            </Row>
          );
        })
      )}
    </div>
  );
}

export default CallList;
