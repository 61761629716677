import { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Layout, Modal, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Box from '../../components/Box/Box';
import TicketsByCateogryChart from '../../components/TicketsByCategoryChart/TicketsByCategoryChart';
import Summary from '../../components/Summary/Summary';
import { useDashboardContext } from '../../context/Dashboard.context';
import TicketResTimesChart from '../../components/TicketResTimeChart/TicketResTimesChart';
import TrendsByContactTypeChart from '../../components/TrendsByChannelChart/TrendsByChannelChart';
import HighLowFrequencyUsersChart from '../../components/HighLowFrequencyUsersChart/HighLowFrequencyUsersChart';
import UserPopulationCharts from '../../components/UserPopulationCharts/UserPopulationCharts';
import IncidentsTicketsSegmentationChart from '../../components/IncidentsTicketsSegmentationChart/IncidentsTicketsSegmentationChart';
import TicketsTreeMapChart from '../../components/TicketsTreeMapChart/TicketsTreeMapChart';
import exportAsPDF from '../../utils/exportAsPDF';
import TopFrequencyWords from '../../components/TopFrequencyWords/TopFrequencyWords';
import { exportCharts } from './dashboardPdfExport';

function Dashboard() {
  const { getVisuals, visualStates } = useDashboardContext();

  useEffect(() => {
    const fetchVisualsData = async () => {
      await getVisuals();
    };

    const hasLoadedVisuals =
      visualStates.ticketVolumesByCategory.isLoaded &&
      visualStates.ticketResolutionTimes.isLoaded &&
      visualStates.ticketTrendsByChannel.isLoaded &&
      visualStates.countUserFrequencyType.isLoaded &&
      visualStates.userPopulation.isLoaded &&
      visualStates.incidentsTicketsBySegment.isLoaded &&
      visualStates.aggregatedIncidentsTicketsBySegment.isLoaded &&
      visualStates.topFrequencyWords.isLoaded;

    if (!hasLoadedVisuals) {
      fetchVisualsData();
    }
  }, []);

  Object.keys(exportCharts).forEach((chart) => {
    exportCharts[chart].ref =
      useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [listOfChartsForDownload, setListOfChartsForDownload] = useState<
    string[]
  >(Object.keys(exportCharts));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    exportAsPDF(
      Object.keys(exportCharts)
        .filter((key) => listOfChartsForDownload.includes(key))
        .reduce(
          (cur, key) => Object.assign(cur, { [key]: exportCharts[key] }),
          {}
        )
    );
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Modal
        title="Export to PDF"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>,
        ]}
      >
        <p>
          Check with parts of dashboard you would like to include in export.
        </p>
        {Object.keys(exportCharts).map((chartId) => (
          <p key={exportCharts[chartId].title}>
            <Checkbox
              id={chartId}
              checked={listOfChartsForDownload.includes(chartId)}
              onClick={() => {
                if (listOfChartsForDownload.includes(chartId)) {
                  setListOfChartsForDownload([
                    ...listOfChartsForDownload.filter(
                      (chart) => chart !== chartId
                    ),
                  ]);
                } else {
                  setListOfChartsForDownload([
                    ...listOfChartsForDownload,
                    chartId,
                  ]);
                }
              }}
            />{' '}
            {exportCharts[chartId].title}
          </p>
        ))}
      </Modal>
      <Space direction="vertical">
        <Button onClick={showModal}>Export PDF</Button>
        <Box
          ref={exportCharts.summaryTable.ref as React.RefObject<HTMLDivElement>}
        >
          <Summary />
        </Box>

        <Box
          ref={
            exportCharts.volumesByCategories
              .ref as React.RefObject<HTMLDivElement>
          }
        >
          <TicketsByCateogryChart />
        </Box>

        <Box
          ref={
            exportCharts.resolutionTimeByAssGroup
              .ref as React.RefObject<HTMLDivElement>
          }
        >
          <TicketResTimesChart />
        </Box>

        <Box
          ref={exportCharts.volumesChart.ref as React.RefObject<HTMLDivElement>}
        >
          <TrendsByContactTypeChart />
        </Box>

        <Box
          ref={
            exportCharts.highLowFrequency.ref as React.RefObject<HTMLDivElement>
          }
        >
          <HighLowFrequencyUsersChart />
        </Box>

        <Box
          ref={
            exportCharts.populationChart.ref as React.RefObject<HTMLDivElement>
          }
        >
          <UserPopulationCharts />
        </Box>

        <Box
          ref={
            exportCharts.incidentsTicketsSegmentation
              .ref as React.RefObject<HTMLDivElement>
          }
        >
          <IncidentsTicketsSegmentationChart />
        </Box>

        <Box
          ref={
            exportCharts.aggregatedIncidentsTicketsSegmentation
              .ref as React.RefObject<HTMLDivElement>
          }
        >
          <TicketsTreeMapChart />
        </Box>

        <Box
          ref={
            exportCharts.topFrequencyWords
              .ref as React.RefObject<HTMLDivElement>
          }
        >
          <TopFrequencyWords />
        </Box>
        {/* <Box>
          <Row gutter={8}>
            <Col span={24}>
              <Typography.Text>
                More dashboards
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Box type="secondary">Col 1</Box>
            </Col>
            <Col span={8}>
              <Box type="secondary">Col 2</Box>
            </Col>
            <Col span={8}>
              <Box type="secondary">Col 3</Box>
            </Col>
          </Row>
        </Box> */}
      </Space>
    </Layout>
  );
}

export default Dashboard;
