import { useState } from 'react';
import { Button, Typography } from 'antd';
import styles from './LoginButton.module.scss';
import authService from '../../services/auth.service';

const EMAIL_TO = process.env.REACT_APP_ACCESS_REQUEST_EMAIL || '';
const EMAIL_SUBJECT = 'Seer Application - Access Request';
const EMAIL_BODY = encodeURIComponent(
  'Hello,\n\n I am requesting access to the Seer Application. My LAN is XXXXXXXXX\n\n I am requesting access because XXXXXXXXXX'
);
const REQUEST_ACCESS_HREF = `mailto:${EMAIL_TO}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`;

function LoginButton() {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginClick = () => {
    setIsLoading(true);
    authService
      .login()
      .then((loginUrl) => loginUrl && window.location.assign(loginUrl));
  };

  return (
    <div className={styles.button}>
      <Button type="primary" onClick={handleLoginClick} loading={isLoading}>
        LOG IN
      </Button>
      <Typography.Text className={styles.footnote}>
        Don&apos;t have access?{' '}
        <a href={REQUEST_ACCESS_HREF} target="blank">
          Request here
        </a>
      </Typography.Text>
    </div>
  );
}

export default LoginButton;
