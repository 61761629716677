import axios from '../axios';
import { LoginResponse } from '../types/Auth';

const authService = {
  login: async (): Promise<string> => {
    try {
      const { data }: LoginResponse = await axios.get('/login');
      return data.basicsResponse.body.loginUrl;
    } catch (e) {
      console.error(e);
      return '';
    }
  },
};

export default authService;
