import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Props {
  className?: string;
  trigger?: 'hover' | 'click';
  children: React.ReactNode;
  placement?: TooltipPlacement;
}

function HelpPopover({
  className,
  trigger = 'click',
  children,
  placement,
}: Props) {
  return (
    <Popover
      className={className}
      trigger={trigger}
      placement={placement}
      content={children}
      arrow={{ pointAtCenter: true }}
      color="white"
    >
      <QuestionCircleOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
    </Popover>
  );
}

export default HelpPopover;
