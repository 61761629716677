export interface ExportCharts {
  [key: string]: {
    title: string;
    ref?: React.MutableRefObject<HTMLDivElement>;
  };
}

export const exportCharts: ExportCharts = {
  summaryTable: {
    title: 'Summary table',
  },
  volumesByCategories: {
    title: 'Ticket volumes by categories',
  },
  resolutionTimeByAssGroup: {
    title: 'Ticket resolution time by assignment group',
  },
  volumesChart: {
    title: 'Ticket volumes charts',
  },
  highLowFrequency: {
    title: 'High frequency, average frequency vs. low frequency users',
  },
  populationChart: {
    title: 'User population charts',
  },
  incidentsTicketsSegmentation: {
    title: 'Incidents & Tickets Segmentation',
  },
  aggregatedIncidentsTicketsSegmentation: {
    title: 'Tickets by Segmentation',
  },
  topFrequencyWords: {
    title: 'Most Frequent Phrases',
  },
};
