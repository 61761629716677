import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import {
  TicketDetails,
  TicketInsights,
  TicketsPayload,
} from '../types/Tickets';
import { TicketsContext } from '../types/Context';
import ticketsService from '../services/tickets.service';
import { DateRange, TicketFilter, TicketFilterOptions } from '../types/Filters';
import TICKET_FILTER_DEFAULT from '../constants/filter';

interface Props {
  children: ReactNode;
}

const Context = createContext<TicketsContext>({} as TicketsContext);

export const useTicketsContext = () => useContext(Context);

export function TicketsProvider({ children }: Props) {
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [insights, setInsights] = useState<TicketInsights>({
    boxData: [],
    graphData: [],
  });
  const [tickets, setTickets] = useState<TicketDetails[]>([]);
  const [filter, setFilter] = useState<TicketFilter>(TICKET_FILTER_DEFAULT);
  const [filterOptions, setFilterOptions] = useState<TicketFilterOptions>({
    requestTypes: [],
    callerIds: [],
    callerDepts: [],
    assignmentGroups: [],
    priorityLevels: [],
    configurationItems: {
      mainCategories: [],
      subCategories: [],
    },
    reassignmentCounts: [],
    locations: [],
    assignees: [],
    callerTitles: [],
    escalationOptions: [],
  });
  const [hasLoadedOptions, setHasLoadedOptions] = useState(false);

  const getInsights = async (ticketFilter = filter) => {
    const response = await ticketsService.getTicketInsights(ticketFilter);
    setInsights(response);
    return response;
  };

  const getTickets = async (
    payload: Partial<TicketsPayload>,
    ticketFilter = filter
  ) => {
    setTickets([]);
    const response = await ticketsService.getTickets({
      ...payload,
      ...ticketFilter,
    });
    setTickets(response.tickets);
    setTotalCount(response.totalCount);
    return response;
  };

  const navigateToTickets = async (ticketFilter: Partial<TicketFilter>) => {
    // get params object, but only if values are defined (do not include undefined or empty values)
    const paramsObj = Object.entries(ticketFilter).reduce<{
      [key: string]: string | string[];
    }>(
      (obj, [key, value]) => ({
        ...obj,
        ...(value && { [key]: value }),
        // if date range, then convert value from { start, end } object to [start, end] array
        ...(key === 'dateRange' && {
          [key]: [(value as DateRange)!.start, (value as DateRange)!.end],
        }),
      }),
      {}
    );
    const searchParams = `${createSearchParams(paramsObj)}`;
    window.open(`/insights/ticket-insights?${searchParams} `, '_blank');
  };

  const getTicketsCsv = async (payload: Partial<TicketsPayload>) => {
    const response = await ticketsService.getTicketsCsv({
      ...payload,
      ...filter,
      isDownload: true,
    });
    return response;
  };

  const getFilterOptions = async () => {
    const options = await ticketsService.getTicketFilterOptions();
    setFilterOptions(options);
    setHasLoadedOptions(true);
    return options;
  };

  const searchFilterOptions = async (
    fieldName: string,
    filterName: string,
    searchString: string
  ) => {
    const data = await ticketsService.searchTicketFilterOptions(
      fieldName,
      filterName,
      searchString
    );
    setFilterOptions({
      ...filterOptions,
      [filterName]: data,
    });
    return data;
  };

  const contextValue = useMemo<TicketsContext>(
    () => ({
      totalCount,
      pageNumber,
      setPageNumber,
      tickets,
      getTickets,
      getTicketsCsv,
      filter,
      setFilter,
      filterOptions,
      getFilterOptions,
      insights,
      getInsights,
      hasLoadedOptions,
      searchFilterOptions,
      navigateToTickets,
    }),
    [
      totalCount,
      pageNumber,
      setPageNumber,
      tickets,
      getTickets,
      getTicketsCsv,
      filter,
      setFilter,
      filterOptions,
      getFilterOptions,
      insights,
      getInsights,
      hasLoadedOptions,
      searchFilterOptions,
      navigateToTickets,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
