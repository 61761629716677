import { useMemo, useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import styles from './SummaryToggle.module.scss';
import { useDashboardContext } from '../../context/Dashboard.context';
import { SummaryTimeframe } from '../../types/Visuals';
import DateSelector from '../DateSelector/DateSelector';
import { DATE_FORMAT } from '../../constants/formatting';
import { formatDate } from '../../utils/date-utils';

const SUMMARY_OPTIONS: { key: SummaryTimeframe; label: string }[] = [
  {
    key: '30-day',
    label: '30-day Summary',
  },
  {
    key: '7-day',
    label: '7-day Summary',
  },
  {
    key: '1-day',
    label: '1-day Summary',
  },
  {
    key: 'custom',
    label: 'Custom timeframe',
  },
];

function SummaryToggle() {
  const {
    summaryTimeframe,
    setSummaryTimeframe,
    summaryDateRange,
    setSummaryDateRange,
    getSummary,
  } = useDashboardContext();

  const [isSelectorDisabled, setIsSelectorDisabled] = useState(false);

  const setDateRangeOfSummary = (timeframe: string) => {
    const days = Number(timeframe.split('-')[0]);

    if (Number.isNaN(days)) {
      setSummaryDateRange(null);
      setIsSelectorDisabled(false);
    } else {
      const currentDate = new Date();
      // 1 day before today - because visual inputs are calculated 1 day ago
      const endDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
      const endDateString = formatDate(endDate);
      // subtract days based on toggle selection
      const startDate = new Date(endDate.setDate(endDate.getDate() - days));
      const startDateString = formatDate(startDate);

      setSummaryDateRange({ start: startDateString, end: endDateString });
      setIsSelectorDisabled(true);
    }
  };

  useEffect(() => {
    setDateRangeOfSummary(summaryTimeframe);
  }, []);

  useEffect(() => {
    const fetchData = async () => getSummary(summaryDateRange);
    fetchData();
  }, [summaryDateRange]);

  const selectedLabel = useMemo(() => {
    const selectedOption = SUMMARY_OPTIONS.find(
      (option) => option.key === summaryTimeframe
    );
    return selectedOption?.label || '';
  }, [summaryTimeframe]);

  const handleSelect = (key: string) => {
    const selectedOption = SUMMARY_OPTIONS.find((option) => option.key === key);
    if (selectedOption) {
      setDateRangeOfSummary(key);
      setSummaryTimeframe(selectedOption.key);
    }
  };

  const onDateSelect = (dates: Array<Dayjs>) => {
    setSummaryDateRange(
      dates
        ? {
            start: dates[0].format(DATE_FORMAT),
            end: dates[1].format(DATE_FORMAT),
          }
        : null
    );
  };

  return (
    <div className={styles.wrapper}>
      <Dropdown
        arrow
        overlayClassName={styles.popup}
        trigger={['click', 'hover']}
        menu={{
          items: SUMMARY_OPTIONS,
          selectable: true,
          selectedKeys: [summaryTimeframe],
          onSelect: (info) => handleSelect(info.key),
        }}
      >
        <Space className={styles.titleWrapper}>
          <Typography.Text className={styles.title}>
            {selectedLabel}
          </Typography.Text>
          <DownOutlined />
        </Space>
      </Dropdown>

      <DateSelector
        onDateRangeChange={onDateSelect}
        defaultValue={summaryDateRange}
        disabled={isSelectorDisabled}
      />
    </div>
  );
}

export default SummaryToggle;
