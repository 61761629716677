import { Row } from 'antd';
import React, { useState } from 'react';
import styles from './TicketsTreeMapLegend.module.scss';
import { TreeMapLegendItem } from '../../types/Visuals';

interface Props {
  legend: Array<TreeMapLegendItem>;
  onLegendItemClick: (hiddenItems: Array<TreeMapLegendItem>) => void;
}

function TicketsTreeMapLegend({ legend, onLegendItemClick }: Props) {
  const [hiddenItems, setHiddenItems] = useState<TreeMapLegendItem[]>([]);

  const legendToggle = (legendItem: TreeMapLegendItem) => {
    const newHiddenItems = hiddenItems.find(
      (item) => item.label === legendItem.label
    )
      ? hiddenItems.filter((item) => item.label !== legendItem.label)
      : [...hiddenItems, legendItem];
    setHiddenItems(newHiddenItems);
    onLegendItemClick(newHiddenItems);
  };

  return (
    <Row gutter={8} justify="space-around" className={styles.legendWrapper}>
      {legend.map(({ label, color }) => (
        <div key={label}>
          <input type="checkbox" id={label} />
          <label
            htmlFor={label}
            role="presentation"
            className={styles.itemWrapper}
            onClick={() => legendToggle({ label, color })}
          >
            <div
              className={styles.legendMarker}
              style={{ background: color }}
            />
            {label}
          </label>
        </div>
      ))}
    </Row>
  );
}

export default TicketsTreeMapLegend;
