import { TicketFilter, SurveyFilter } from '../types/Filters';

const TICKET_FILTER_DEFAULT: TicketFilter = {
  dateRange: null,
  requestType: [],
  callerId: [],
  callerDept: [],
  assignmentGroup: [],
  priority: [],
  location: null,
  description: null,
  cmdbCiMainCategory: null,
  cmdbCiSubCategory: null,
  reassignmentCount: null,
  assignedTo: null,
  callerTitle: null,
  escalation: null,
};

export const SURVEY_FILTER_DEFAULT: SurveyFilter = {
  dateRange: null,
  requestType: [],
  callerId: [],
  callerDept: [],
  assignmentGroup: [],
  callerManager: [],
  description: null,
  sempraDir: [],
  question: [],
  scaledValue: [],
  stringValue: null,
};

export default TICKET_FILTER_DEFAULT;
