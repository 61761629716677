import axios from '../axios';
import { DateRange } from '../types/Filters';
import {
  GetSummaryResponse,
  GetVisualsDataResponse,
  SummaryData,
  VisualFilters,
} from '../types/Visuals';
import VisualName from '../types/enums/Visuals';
import removeNullUndefined from '../utils/obj-utils';
import { parseFilters } from '../utils/visual-utils';

const visualsService = {
  getVisual: async <T>(
    visualName: VisualName,
    responseOnError: T,
    filters?: VisualFilters
  ): Promise<T> => {
    const parsedFilters = filters && parseFilters(filters);

    try {
      const { data } = await axios.post<GetVisualsDataResponse<T>>('/visuals', {
        visualName,
        filters: parsedFilters,
      });
      return data.basicsResponse.body;
    } catch (error) {
      return responseOnError;
    }
  },

  getSummary: async (dates: DateRange): Promise<SummaryData> => {
    const filteredPayload = removeNullUndefined({ dates });

    try {
      const { data }: GetSummaryResponse = await axios.post(
        '/summary',
        filteredPayload
      );
      return data.basicsResponse.body;
    } catch (e) {
      return {
        summary: {},
        outliers: {},
        graph: {
          default: {
            opened: [],
            resolved: [],
          },
          custom: {
            opened: [],
            resolved: [],
          },
        },
      };
    }
  },
};

export default visualsService;
