import { InteractionItemType } from '../../types/Calls';
import { formatDateTime, msToTime } from '../../utils/date-utils';

interface CallTagsProps {
  type: InteractionItemType;
  startDate: string;
  endDate: string;
  duration: number;
  noTalkTime: number;
  interruptions: number;
  contactID?: string;
  agentResponseTime: number;
}

function CallData({
  type,
  startDate,
  endDate,
  duration,
  noTalkTime,
  interruptions,
  contactID,
  agentResponseTime,
}: CallTagsProps) {
  return type === 'chat' ? (
    <div>
      <p>
        <strong>Chat Start Timestamp:</strong>
        <br /> {formatDateTime(startDate)}
      </p>
      <p>
        <strong>Chat End Timestamp:</strong>
        <br /> {formatDateTime(endDate)}
      </p>
      <p>
        <strong>Duration</strong>
        <br /> {msToTime(duration)}
      </p>
      <p>
        <strong>Average Agent Response Time</strong>
        <br /> {msToTime(agentResponseTime)}
      </p>
      <p>
        <strong>Ticket ID:</strong>
        <br /> {contactID}
      </p>
    </div>
  ) : (
    <div>
      <p>
        <strong>Call Start Timestamp:</strong>
        <br /> {formatDateTime(startDate)}
      </p>
      <p>
        <strong>Call End Timestamp:</strong>
        <br /> {formatDateTime(endDate)}
      </p>
      <p>
        <strong>Duration</strong>
        <br /> {msToTime(duration)}
      </p>
      <p>
        <strong>No-Talk Time</strong>
        <br /> {msToTime(noTalkTime)}
      </p>
      <p>
        <strong>Interruptions</strong>
        <br /> {interruptions}
      </p>
      <p>
        <strong>Contact ID:</strong>
        <br /> {contactID}
      </p>
    </div>
  );
}

export default CallData;
