import axios, { AxiosError } from 'axios';
import { SessionRefreshResponse } from './types/Auth';

const axiosInstace = axios.create();
axiosInstace.interceptors.request.use((config) => {
  const idToken = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('idToken'))
    ?.split('=')[1];

  const newConfig = { ...config };
  newConfig.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
  newConfig.headers.Authorization = `Bearer ${idToken}`;
  newConfig.baseURL = process.env.REACT_APP_API_URL;
  return newConfig;
});

const handle401Error = (error: AxiosError) => {
  // Get original request - will be used later to re-try after new tokens are fetched
  const originalRequest = error.config;

  // Get refresh token out of the cookies - will be used to fetch new tokens
  const refreshToken = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('refreshToken'))
    ?.split('=')[1];

  if (refreshToken && originalRequest) {
    // Send request to refresh the tokens
    return axiosInstace
      .post<SessionRefreshResponse>('/session-refresh', { refreshToken })
      .then((response) => {
        // New tokens are successfully fetched
        const { cookies } = response.data.basicsResponse.body;
        // Put all recevied cookies (tokens) to frontend domain
        cookies.forEach((cookie) => {
          document.cookie = cookie;
        });
        // Send the original request again (re-try)
        return axiosInstace.request(originalRequest);
      })
      .catch((refreshError) => {
        // Error fetching the tokens, navigate to login page and reject the api request
        window.location.href = '/login';
        return Promise.reject(refreshError);
      });
  }

  // In case of any issues just reject the api request
  return Promise.reject(error);
};

axiosInstace.interceptors.response.use(
  async (response) => {
    const contentType = response.headers['content-type'].split(';');
    // skip checking of statusCode when file is being downloaded
    if (contentType.includes('text/csv')) {
      return response;
    }

    // if response is not 200, then throw error
    if (response.data.basicsResponse.statusCode !== 200) {
      return Promise.reject(response.data);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return handle401Error(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstace;
