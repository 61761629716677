import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Layout, Row, Space, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import callsService from '../../services/calls.service';
import { CallDetails } from '../../types/Calls';

import styles from './CallDetail.module.scss';
import BorderedBox from '../../components/BorderedBox/BorderedBox';
import CallSummary from '../../components/CallSummary/CallSummary';
import CallTranscript from '../../components/CallTranscript/CallTranscript';
import CallTags from '../../components/CallTags/CallTags';
import CallData from '../../components/CallData/CallData';
import CallTimeline from '../../components/CallTimeline/CallTimeline';
import CallSegmentShare from '../../components/CallSegmentShare/CallSegmentShare';
import INTERACTION_ITEM_MAPPING from '../../constants/mapping';

function CallDetail() {
  const { id } = useParams();
  const [callDetails, setCallDetails] = useState<CallDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        const callData = await callsService.getCallById(id).finally(() => {
          setIsLoading(false);
        });
        setCallDetails(callData);
      }
    };
    fetchData();
  }, [id]);

  const callDetailComponent = () => {
    if (!callDetails) return <span>Details for ID: {id} not found</span>;
    const { type } = callDetails;
    const segmentation = callDetails.segmentation || [];
    const sentiment = callDetails.sentiment || {};
    const summary = callDetails.callSummary;
    const interactionItemType = INTERACTION_ITEM_MAPPING[type];

    return (
      <Layout>
        <Space direction="vertical" size="large">
          <Space direction="vertical" className={styles.fullWidth}>
            <Row gutter={8}>
              <Col span={6}>
                <Typography.Text>Summary</Typography.Text>
              </Col>

              <Col span={6}>
                <Typography.Text>Segment share</Typography.Text>
              </Col>

              <Col span={6}>
                <Typography.Text>{interactionItemType} tags</Typography.Text>
              </Col>

              <Col span={6}>
                <Typography.Text>{interactionItemType} data</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={6}>
                <BorderedBox className={styles.fullHeight}>
                  <CallSummary
                    className={styles.callSummary}
                    sentimentClassName={styles.sentiment}
                    callSummary={summary}
                    sentiment={sentiment}
                  />
                </BorderedBox>
              </Col>

              <Col span={6}>
                <BorderedBox className={styles.fullHeight}>
                  {type === 'call' ? (
                    <CallSegmentShare segmentation={segmentation} />
                  ) : (
                    <Typography.Text>Not Available for Chats</Typography.Text>
                  )}
                </BorderedBox>
              </Col>

              <Col span={6}>
                <BorderedBox className={styles.fullHeight}>
                  <CallTags tags={callDetails.tags || []} />
                </BorderedBox>
              </Col>

              <Col span={6}>
                <BorderedBox className={styles.fullHeight}>
                  <CallData {...callDetails} />
                </BorderedBox>
              </Col>
            </Row>
          </Space>

          <Space direction="vertical" className={styles.fullWidth}>
            <Row gutter={8}>
              <Col span={12}>
                <Typography.Text>
                  {interactionItemType} Sentiment Timeline
                </Typography.Text>
              </Col>

              <Col span={12}>
                <Typography.Text>
                  {interactionItemType} Transcript
                </Typography.Text>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <BorderedBox className={styles.fullHeight}>
                  <CallTimeline
                    timelineItems={callDetails.transcript || []}
                    agentSentiment={sentiment.agent}
                    callerSentiment={sentiment.caller}
                    type={type}
                  />
                </BorderedBox>
              </Col>

              <Col span={12}>
                <BorderedBox
                  className={styles.fullHeight}
                  style={{ maxHeight: '450px', overflow: 'auto' }}
                >
                  <CallTranscript
                    transcript={callDetails.transcript || []}
                    type={type}
                  />
                </BorderedBox>
              </Col>
            </Row>
          </Space>
        </Space>
      </Layout>
    );
  };

  return isLoading ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    callDetailComponent()
  );
}

export default CallDetail;
