import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState } from 'react';

interface Props {
  className?: string;
  src: string;
  alt: string;
  style: React.CSSProperties;
  loading?: 'lazy' | 'eager';
}

function Img({ className, src, alt, style, loading }: Props) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <img
        className={className}
        src={src}
        alt={alt}
        style={{
          display: isLoading ? 'none' : 'inline',
          ...style,
        }}
        loading={loading}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
      />

      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 24, margin: '8px 64px' }} />
          }
        />
      )}
    </>
  );
}

export default Img;
