import axios from '../axios';
import { Option, TicketFilter, TicketFilterOptions } from '../types/Filters';
import {
  TicketFilterOptionsResponse,
  TicketByIdResponse,
  TicketsResponse,
  TicketsPayload,
  Tickets,
  TicketInsights,
  TicketInsightsResponse,
  SearchTicketFilterOptionsResponse,
} from '../types/Tickets';
import removeNullUndefined from '../utils/obj-utils';

const ticketsService = {
  getTicketFilterOptions: async (): Promise<TicketFilterOptions> => {
    try {
      const { data }: TicketFilterOptionsResponse = await axios.get(
        '/tickets/filter-options'
      );

      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        requestTypes: [],
        callerIds: [],
        callerDepts: [],
        assignmentGroups: [],
        priorityLevels: [],
        configurationItems: {
          mainCategories: [],
          subCategories: [],
        },
        reassignmentCounts: [],
        locations: [],
        assignees: [],
        callerTitles: [],
        escalationOptions: [],
      };
    }
  },
  searchTicketFilterOptions: async (
    fieldName: string,
    filterName: string,
    searchString: string
  ): Promise<Option[]> => {
    try {
      const { data }: SearchTicketFilterOptionsResponse = await axios.post(
        `/tickets/filter-options/${fieldName}`,
        {
          filterName,
          searchString,
        }
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  getTicketById: async (id: string) => {
    try {
      const { data }: TicketByIdResponse = await axios.get(`/tickets/${id}`);

      const { body, statusCode } = data.basicsResponse;
      if (statusCode !== 200) {
        return null;
      }
      return body;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  getTickets: async (payload: Partial<TicketsPayload>): Promise<Tickets> => {
    const filteredPayload = removeNullUndefined(payload);

    try {
      const { data }: TicketsResponse = await axios.post(
        '/tickets',
        filteredPayload
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        tickets: [],
        totalCount: 0,
      };
    }
  },
  getTicketsCsv: async (payload: Partial<TicketsPayload>): Promise<Blob> => {
    try {
      const file = await axios.post('/tickets/download', payload);

      return file.data;
    } catch (e) {
      console.error(e);
      return new Blob();
    }
  },
  getTicketInsights: async (
    payload: Partial<TicketFilter>
  ): Promise<TicketInsights> => {
    const filteredPayload = removeNullUndefined(payload);

    try {
      const { data }: TicketInsightsResponse = await axios.post(
        '/tickets/insights',
        filteredPayload
      );
      return data.basicsResponse.body;
    } catch (e) {
      console.error(e);
      return {
        boxData: [],
        graphData: [],
      };
    }
  },
};

export default ticketsService;
