// Column definitions for Table Component inside

import { formatDate } from '../utils/date-utils';
import { formatAsSNOWLink } from './formatting';

export const SURVEY_COLUMNS = [
  {
    title: 'Ticket ID',
    dataIndex: 'ticketID',
    key: 'ticketID',
    width: 100,
  },
  {
    title: 'Survey ID',
    dataIndex: 'surveyID',
    key: 'surveyID',
    width: 100,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 120,
    render: formatDate,
  },
  {
    title: 'Request Type',
    dataIndex: 'requestType',
    key: 'requestType',
    width: 150,
  },
  {
    title: 'Caller ID',
    dataIndex: 'callerID',
    key: 'callerID',
    width: 150,
  },
  {
    title: 'Caller Dept.',
    dataIndex: 'callerDepartment',
    key: 'callerDepartment',
    width: 200,
  },
  {
    title: 'Assignment Group',
    dataIndex: 'assignmentGroup',
    key: 'assignmentGroup',
    width: 200,
  },
  {
    title: 'Sempra Director',
    dataIndex: 'sempraDirector',
    key: 'contactType',
    width: 150,
  },
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
    width: 200,
  },
  {
    title: 'Scaled Value',
    dataIndex: 'scaledValue',
    key: 'scaledValue',
    width: 100,
  },
  {
    title: 'Comments',
    dataIndex: 'stringValue',
    key: 'stringValue',
  },
  {
    title: 'Short description',
    dataIndex: 'shortDescription',
    key: 'shortDescription',
    width: 400,
  },
];

const COLUMNS = [
  {
    title: 'Ticket ID',
    dataIndex: 'ticketID',
    key: 'ticketID',
    width: 100,
    render: formatAsSNOWLink,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 150,
    render: formatDate,
  },

  {
    title: 'Request Type',
    dataIndex: 'requestType',
    key: 'requestType',
    width: 150,
  },
  {
    title: 'Caller ID',
    dataIndex: 'callerID',
    key: 'callerID',
    width: 250,
  },
  {
    title: 'Caller Dept.',
    dataIndex: 'callerDepartment',
    key: 'callerDepartment',
    width: 200,
  },
  {
    title: 'Assignment Group',
    dataIndex: 'assignmentGroup',
    key: 'assignmentGroup',
    width: 200,
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: 150,
  },
  {
    title: 'Category',
    dataIndex: 'cmdbCategory',
    key: 'cmdbCategory',
    width: 200,
  },
  {
    title: 'Subcategory',
    dataIndex: 'cmdbSubcategory',
    key: 'cmdbSubcategory',
    width: 200,
  },
  {
    title: 'Reassignment Count',
    dataIndex: 'reassignmentCount',
    key: 'reassignmentCount',
    width: 200,
  },
  {
    title: 'Caller Title',
    dataIndex: 'callerTitle',
    key: 'callerTitle',
    width: 150,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: 200,
  },
  {
    title: 'Escalation',
    dataIndex: 'escalation',
    key: 'escalation',
    width: 100,
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedTo',
    key: 'assignedTo',
    width: 150,
  },
  {
    title: 'Short description',
    dataIndex: 'shortDescription',
    key: 'shortDescription',
    width: 400,
  },
  {
    title: 'Contact Type',
    dataIndex: 'contactType',
    key: 'contactType',
    width: 150,
  },
];

export default COLUMNS;
