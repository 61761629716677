import { Divider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CallList from '../../components/CallList/CallList';
import styles from './InteractionSentiment.module.scss';
import callsService from '../../services/calls.service';
import { useCallsContext } from '../../context/Calls.context';
import { CallsPayload } from '../../types/Calls';
import Pagination from '../../components/Pagination/Pagination';
import CallFilters from '../../components/CallFilters/CallFilters';

function InteractionSentiment() {
  const {
    totalCount,
    pageNumber,
    setPageNumber,
    calls,
    getCalls,
    getFilterOptions,
    hasLoadedOptions,
  } = useCallsContext();

  const [isLoading, setIsLoading] = useState(false);

  const fetchCalls = async (payload: Partial<CallsPayload>) => {
    setIsLoading(true);
    await getCalls(payload);
    setIsLoading(false);
  };

  useEffect(() => {
    // get filter options - if not loaded already
    if (!hasLoadedOptions) {
      getFilterOptions();
    }
    // get all calls - if not loaded already
    if (calls.length === 0) {
      fetchCalls({ pageNumber: 1 });
    }
  }, [callsService]);

  const navigate = useNavigate();

  const onSearch = (ticketId: string) =>
    navigate(`/interaction-sentiment/${ticketId}`);

  const onFilterSearch = () => {
    setPageNumber(1);
    fetchCalls({ pageNumber: 1 });
  };

  return (
    <>
      <Input.Search
        className={styles.searchBar}
        placeholder="Search Contact ID / Incident #"
        onSearch={onSearch}
      />
      <CallFilters isLoading={isLoading} onSearch={onFilterSearch} />
      <Divider />
      <CallList callList={calls} isLoading={isLoading} />
      {calls.length > 0 && (
        <Pagination
          totalItems={totalCount}
          currentPage={pageNumber}
          onChange={(newPage) => {
            if (pageNumber !== newPage) {
              setPageNumber(newPage);
              // get calls data for currently selected page
              fetchCalls({ pageNumber: newPage });
            }
          }}
        />
      )}
    </>
  );
}

export default InteractionSentiment;
