import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import styles from './Select.module.scss';

function Select<T>(props: SelectProps<T>) {
  return (
    <AntdSelect<T>
      {...props}
      className={styles.select}
      popupClassName={styles.selectPopup}
    />
  );
}

export default Select;
