import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import {
  Chart as ChartJS,
  PointElement,
  Tooltip,
  Legend,
  LogarithmicScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Filler,
  LineElement,
  ArcElement,
} from 'chart.js';

import Router from './components/Router/Router';
import appTheme from './theme/theme';
import { CallsProvider } from './context/Calls.context';
import { TicketsProvider } from './context/Tickets.context';
import { DashboardProvider } from './context/Dashboard.context';
import { SurveysProvider } from './context/Surveys.context';

// Register Chart.js Elements
ChartJS.register(
  LogarithmicScale,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Filler,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale
);

function App() {
  return (
    <ConfigProvider theme={appTheme}>
      <DashboardProvider>
        <CallsProvider>
          <BrowserRouter>
            <TicketsProvider>
              <SurveysProvider>
                <Router />
              </SurveysProvider>
            </TicketsProvider>
          </BrowserRouter>
        </CallsProvider>
      </DashboardProvider>
    </ConfigProvider>
  );
}

export default App;
