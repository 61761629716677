import { Tag } from 'antd';
import styles from './Sentiment.module.scss';

interface SentimentProps {
  className?: string;
  agentSentiment: string;
  callerSentiment: string;
}

export const getTag = (sentimentValue: string) => {
  let tagColor;
  switch (sentimentValue) {
    case 'POSITIVE':
      tagColor = 'success';
      break;
    case 'NEUTRAL':
      tagColor = 'warning';
      break;
    case 'NEGATIVE':
      tagColor = 'error';
      break;
    default:
      return null;
  }

  return (
    <Tag className={styles.sentimentTag} color={tagColor}>
      {sentimentValue}
    </Tag>
  );
};

function Sentiment({
  className = '',
  agentSentiment,
  callerSentiment,
}: SentimentProps) {
  return (
    <div className={`${styles.sentiment} ${className}`}>
      {/* <div>Ending Sentiment {getTag(sentiment.ending)}</div> */}
      <div>Agent Sentiment {getTag(agentSentiment)}</div>
      <div>Caller Sentiment {getTag(callerSentiment)}</div>
    </div>
  );
}

export default Sentiment;
