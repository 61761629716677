import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Typography, Empty } from 'antd';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { BAR_CHART_COLORS } from '../../constants/visuals';
import styles from './IncidentsTicketsSegmentationChart.module.scss';
import Box from '../Box/Box';
import { useDashboardContext } from '../../context/Dashboard.context';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

const { primary } = BAR_CHART_COLORS;

const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
  },
};

function IncidentsTicketsBySegmentChart() {
  const {
    visualStates: { incidentsTicketsBySegment },
    getVisualByName,
  } = useDashboardContext();

  const [companyChartData, setCompanyChartData] = useState<ChartData<'bar'>>();
  const [employeeChartData, setEmployeeChartData] =
    useState<ChartData<'bar'>>();

  useEffect(() => {
    if (incidentsTicketsBySegment.data?.companySegment) {
      const labels = incidentsTicketsBySegment.data.companySegment.map(
        (company) => company.name
      );
      const counts = incidentsTicketsBySegment.data.companySegment.map(
        (company) => company.count
      );
      const data = {
        labels,
        datasets: [
          {
            data: counts,
            backgroundColor: labels.map(
              (label, index) => primary[index % primary.length]
            ),
          },
        ],
      };
      setCompanyChartData(data);
    }

    if (incidentsTicketsBySegment.data?.employeeTypeSegment) {
      const labels = incidentsTicketsBySegment.data.employeeTypeSegment.map(
        (employee) => employee.name
      );
      const counts = incidentsTicketsBySegment.data.employeeTypeSegment.map(
        (employee) => employee.count
      );

      const employeeData = {
        labels,
        datasets: [
          {
            data: counts,
            backgroundColor: labels.map(
              (_, index) => primary[index % primary.length]
            ),
          },
        ],
      };
      setEmployeeChartData(employeeData);
    }
  }, [incidentsTicketsBySegment]);

  return !incidentsTicketsBySegment.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <Row gutter={[8, 8]}>
      <Col md={24} lg={12}>
        <Row style={{ paddingBottom: 8 }}>
          <Typography.Text className={styles.tileTitle}>
            Incidents & Tickets Segmentation
          </Typography.Text>
        </Row>

        {/* Chart section */}
        <Box type="secondary">
          {/* If data not loaded then show "No Data" message, otherwise show the chart */}
          {!companyChartData || companyChartData.labels?.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            <Bar options={options} data={companyChartData} />
          )}
        </Box>
      </Col>
      <Col md={24} lg={12}>
        <Row justify="space-between">
          <Typography.Text className={styles.tileTitle}>
            Tickets by Resource Type
          </Typography.Text>

          <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
            <Col>
              <ChartFilters
                value={{
                  dataSource: incidentsTicketsBySegment.dataSource,
                  timeframe: incidentsTicketsBySegment.timeframe,
                  timeframeType: incidentsTicketsBySegment.timeframeType,
                }}
                options={[
                  { label: 'Incident', value: 'incidents' },
                  { label: 'Request', value: 'requests' },
                  { label: 'Survey', value: 'surveys' },
                ]}
                onChange={(value) => {
                  getVisualByName(
                    VisualName.IncidentsTicketsBySegment,
                    null,
                    value
                  );
                }}
              />
            </Col>

            <Col>
              <HelpPopover placement="bottomRight">
                <Img
                  src="./images/help/incidents-tickets-segmentation.jpeg"
                  alt="Description of Tickets by Resource Type chart"
                  style={{
                    color: 'black',
                    width: '90vw',
                    maxWidth: '1000px',
                  }}
                />
              </HelpPopover>
            </Col>
          </Row>
        </Row>

        {/* Chart section */}
        <Box type="secondary">
          {/* If data not loaded then show "No Data" message, otherwise show the chart */}
          {!employeeChartData || employeeChartData.labels?.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            <Bar options={options} data={employeeChartData} />
          )}
        </Box>
      </Col>
    </Row>
  );
}

export default IncidentsTicketsBySegmentChart;
