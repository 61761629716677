import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { ExportCharts } from '../pages/Dashboard/dashboardPdfExport';

const minPdfImageBottomMargin = 10;
const standardTopMarginBetweenObjects = 10;
const pdfImageTopMargin = 10;
const pdfImageLeftMargin = 10;
const pdfPageHeight = 298;
const pdfPageWidth = 298;

const addOneElement = async (
  elements: ExportCharts,
  doc: any,
  currentRemainingHeight: number = pdfPageHeight
) => {
  let remainingHeight = currentRemainingHeight;
  const firstKey = Object.keys(elements)[0];
  const chart = elements[firstKey];

  if (!chart.ref) return;
  const imageHeight = chart.ref?.current.clientHeight;
  const imageWidth = chart.ref?.current.clientWidth;
  const canvas = await html2canvas(chart.ref?.current);
  const pdfImageWidth = 180;

  const pdfImageHeight = (imageHeight / imageWidth) * pdfImageWidth;

  if (
    remainingHeight <
    pdfImageHeight + pdfImageTopMargin + minPdfImageBottomMargin
  ) {
    doc.addPage();
    doc.setFillColor('#0e263b'); // rgb(14, 38, 59)
    doc.rect(0, 0, pdfPageWidth, pdfPageHeight, 'F');

    remainingHeight = pdfPageHeight;
  }

  doc.addImage(
    canvas,
    'JPEG',
    pdfImageLeftMargin,
    pdfPageHeight - remainingHeight + standardTopMarginBetweenObjects,
    pdfImageWidth,
    pdfImageHeight,
    chart.title,
    'SLOW'
  );

  remainingHeight -= pdfImageHeight + pdfImageTopMargin;

  const newElements = elements;
  delete newElements[firstKey];

  if (Object.keys(newElements).length > 0) {
    await addOneElement(newElements, doc, remainingHeight);
  }
};

const exportAsPDF = async (elements: ExportCharts) => {
  const doc = new JsPDF();

  doc.setFillColor('#0e263b'); // rgb(14, 38, 59)
  doc.rect(0, 0, pdfPageWidth, pdfPageHeight, 'F');
  let remainingHeight = pdfPageHeight - 5;

  // Add heading
  doc.setTextColor('#ffffff');
  doc.setFontSize(20);
  doc.text(
    'SEER Dashboard export',
    pdfImageLeftMargin,
    pdfPageHeight - remainingHeight + standardTopMarginBetweenObjects
  );
  remainingHeight -= 10;

  // add subheading
  const d = new Date();
  const datestring = `${`0${d.getMonth() + 1}`.slice(
    -2
  )}/${`0${d.getDate()}`.slice(
    -2
  )}/${d.getFullYear()} ${`0${d.getHours()}`.slice(
    -2
  )}:${`0${d.getMinutes()}`.slice(-2)}`;

  doc.setFontSize(10);
  doc.text(
    `Export date/time: ${datestring}`,
    pdfImageLeftMargin,
    pdfPageHeight - remainingHeight + standardTopMarginBetweenObjects
  );
  remainingHeight -= 10;

  await addOneElement(elements, doc, remainingHeight);

  doc.save('export_dashboard.pdf');
};

export default exportAsPDF;
