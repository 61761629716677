import { Table } from 'antd';
import styles from './DataTable.module.scss';
import { PaginationProps } from '../../types/Pagination';
import Pagination from '../Pagination/Pagination';

interface DataTableProps {
  rowKey?: string;
  dataSource: Array<any>;
  columns: Array<Object>;
  pagination: PaginationProps;
  isFilterOpen: boolean;
}
function DataTable({
  rowKey = 'ticketID',
  dataSource,
  columns,
  pagination,
  isFilterOpen,
}: DataTableProps) {
  const tableClass = isFilterOpen
    ? `${styles.tableDesign} ${styles.narrow}`
    : styles.tableDesign;
  return (
    <>
      <Table
        rowKey={rowKey}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size="small"
        className={tableClass}
        rowClassName={(_, index) =>
          index % 2 === 0 ? styles.tableLightRow : styles.tableDarkRow
        }
        scroll={{ x: '150%' }}
      />
      <Pagination
        totalItems={pagination.totalItems}
        currentPage={pagination.currentPage}
        onChange={pagination.onChange}
      />
    </>
  );
}

export default DataTable;
