import { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Layout, Row, Space, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Box from '../../components/Box/Box';
import styles from './SurveyInsights.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import HelpPopover from '../../components/HelpPopover/HelpPopover';
import Img from '../../components/Img/Img';
import { useSurveysContext } from '../../context/Surveys.context';
import { SurveysPayload } from '../../types/Surveys';
import { SurveyFilter } from '../../types/Filters';
import { SURVEY_FILTER_DEFAULT } from '../../constants/filter';
import { SURVEY_COLUMNS } from '../../constants/table';
import SurveyFilters from '../../components/SurveyFilters/SurveyFilters';

function SurveyInsights() {
  const {
    totalCount,
    pageNumber,
    setPageNumber,
    surveys,
    getSurveys,
    setFilter,
  } = useSurveysContext();
  const [searchParams] = useSearchParams();

  const [isLoadingSurveys, setIsLoadingSurveys] = useState(false);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const fetchSurveys = async (
    payload: Partial<SurveysPayload>,
    filter?: SurveyFilter
  ) => {
    setIsLoadingSurveys(true);
    await getSurveys(payload, filter);
    setIsLoadingSurveys(false);
  };

  // transform searchParams object into SurveyFilter structure
  const queryFilter = useMemo<Partial<SurveyFilter>>(() => {
    const keys = [...new Set(searchParams.keys())];
    const { dateRange, assignmentGroup, description } = keys.reduce<{
      [key: string]: string[];
    }>(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: searchParams.getAll(currentValue),
      }),
      {}
    );

    return {
      ...(dateRange && {
        dateRange: {
          start: dateRange[0],
          end: dateRange[1],
        },
      }),
      ...(assignmentGroup && { assignmentGroup }),
      ...(description && { description: description[0] }),
    };
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size) {
      // when query/search params are present
      const newFilter = { ...SURVEY_FILTER_DEFAULT, ...queryFilter };
      setFilter(newFilter);
      fetchSurveys({ pageNumber: 1 }, newFilter);
    } else if (surveys.length === 0) {
      // when page visited without any search/query params
      fetchSurveys({ pageNumber: 1 });
    }
  }, [queryFilter]);

  return (
    <Layout>
      <Space direction="vertical" size={24}>
        <Box>
          <Row gutter={8}>
            <Col span={7}>
              <Typography.Text className={styles.areaTitle}>
                Intro Text
              </Typography.Text>
            </Col>
            <Col span={11}>
              <Row justify="space-between">
                <Typography.Text className={styles.areaTitle}>
                  Output Box
                </Typography.Text>

                <HelpPopover>
                  <Img
                    src="./images/help/ticket-output-boxes.jpeg"
                    alt="Description of Output Boxes"
                    style={{
                      color: 'black',
                      width: '90vw',
                      maxWidth: '750px',
                    }}
                  />
                </HelpPopover>
              </Row>
            </Col>
            <Col span={6}>
              <Typography.Text className={styles.areaTitle}>
                Dynamic Visual Graph
              </Typography.Text>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={7}>
              <Box className={styles.colHeight} type="secondary">
                <div>
                  <Typography.Text strong>
                    This selection includes the quality assessment data from the
                    surveys submitted from users post-fulfillment. This data
                    represents how well the service desk meet the needs of the
                    requester/employee.
                  </Typography.Text>
                </div>
                <br />
                <div>
                  <Typography.Text>
                    Simply select the specified values within each filter and
                    press &apos;Search&apos; to receive your queried results.
                  </Typography.Text>
                </div>
              </Box>
            </Col>
            <Col span={11}>
              <Box type="secondary" className={styles.colHeight}>
                <h5>Coming Soon ...</h5>
              </Box>
            </Col>
            <Col span={6}>
              <Box type="secondary" className={styles.graphBox}>
                <h5>Coming Soon ...</h5>
              </Box>
            </Col>
          </Row>
        </Box>

        <SurveyFilters
          setIsLoading={setIsLoadingSurveys}
          showAdditionalFilters={showAdditionalFilters}
          setShowAdditionalFilters={setShowAdditionalFilters}
          onSubmit={() => {
            setPageNumber(1);
            fetchSurveys({ pageNumber: 1 });
          }}
          totalCount={totalCount}
        />

        {isLoadingSurveys ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
          </div>
        ) : (
          <DataTable
            rowKey="id"
            dataSource={surveys}
            columns={SURVEY_COLUMNS}
            isFilterOpen={false}
            pagination={{
              currentPage: pageNumber,
              totalItems: totalCount,
              onChange: (newPage: number) => {
                setPageNumber(newPage);
                // get surveys data for currently selected page
                fetchSurveys({ pageNumber: newPage });
              },
            }}
          />
        )}
      </Space>
    </Layout>
  );
}

export default SurveyInsights;
