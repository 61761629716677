import styles from './CallSummary.module.scss';
import Sentiment from '../Sentiment/Sentiment';

interface CallSummaryProps {
  className?: string;
  sentimentClassName?: string;
  callSummary: string;
  sentiment: {
    agent: string;
    caller: string;
  };
}
function CallSummary({
  className = '',
  sentimentClassName = '',
  callSummary,
  sentiment,
}: CallSummaryProps) {
  return callSummary ? (
    <div className={`${styles.callSummary} ${className}`}>
      <span className={styles.description}>{callSummary}</span>

      {sentiment ? (
        <Sentiment
          className={sentimentClassName}
          agentSentiment={sentiment.agent}
          callerSentiment={sentiment.caller}
        />
      ) : null}
    </div>
  ) : (
    <div>No summary available</div>
  );
}

export default CallSummary;
