import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Select from '../Select/Select';
import { ChartFiltersValue, TimeframeType } from '../../types/Visuals';
import {
  generateMonthlyOptions,
  generateQuarterlyOptions,
  generateYearlyOptions,
} from '../../utils/visual-utils';
import { TIMEFRAME_TYPE_OPTIONS } from '../../constants/visuals';

interface Props {
  value?: ChartFiltersValue;
  onChange: (value: ChartFiltersValue) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  disabled?: boolean;
  optionsUntil?: string;
}

function ChartFilters({
  value,
  options,
  onChange,
  disabled = false,
  optionsUntil = '',
}: Props) {
  const [timeframeType, setTimeframeType] = useState<TimeframeType>();
  const [timeframe, setTimeframe] = useState<string>();
  const [dataSource, setDataSource] = useState<string>();

  // Set value if provided from props
  useEffect(() => {
    setTimeframeType(value?.timeframeType);
    setTimeframe(value?.timeframe);
    setDataSource(value?.dataSource);
  }, [value]);

  // Gets timeframe options based on the selected timeframe type (if no type selected, then no timeframe options are available)
  const getTimeframeOptions = () => {
    switch (timeframeType) {
      case 'monthly':
        return generateMonthlyOptions(optionsUntil);
      case 'quarterly':
        return generateQuarterlyOptions();
      case 'yearly':
        return generateYearlyOptions();
      default:
        return undefined;
    }
  };

  const handleTimeframeTypeChange = (newTimeframeType: TimeframeType) => {
    // If different timeframe type is selected than before then reset the selected timeframe
    if (timeframeType !== newTimeframeType) {
      setTimeframeType(newTimeframeType);
      setTimeframe(undefined);
    }
  };

  const handleTimeframeChange = (newTimeframe: string) => {
    setTimeframe(newTimeframe);
    // Send onChange event only if both selects are having value
    if (timeframeType && newTimeframe) {
      onChange({ timeframeType, timeframe: newTimeframe, dataSource });
    }
  };

  const handleDataSourceChange = (newDataSource: string) => {
    if (dataSource !== newDataSource) {
      setDataSource(newDataSource);
    }
    onChange({ timeframeType, timeframe, dataSource: newDataSource });
  };

  return (
    <Row gutter={8} align="middle">
      <Col>
        <span>Data type:</span>
      </Col>
      <Col>
        <Select
          placeholder="Select"
          value={dataSource}
          onChange={handleDataSourceChange}
          options={options}
          style={{ minWidth: 110 }}
          disabled={disabled}
        />
      </Col>
      <Col>
        <span>Time:</span>
      </Col>
      <Col>
        <Select
          placeholder="Select"
          value={timeframeType}
          onChange={handleTimeframeTypeChange}
          options={TIMEFRAME_TYPE_OPTIONS}
          style={{ minWidth: 110 }}
          disabled={disabled}
        />
      </Col>
      <Col>
        <Select
          placeholder="Select"
          value={timeframe}
          onChange={handleTimeframeChange}
          options={getTimeframeOptions()}
          style={{ minWidth: 110 }}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
}

export default ChartFilters;
