import {
  Button,
  Col,
  Divider,
  Layout,
  Menu,
  Modal,
  Row,
  Typography,
  theme,
  Radio,
} from 'antd';
import type { RadioChangeEvent } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons/lib/components/Icon';
import {
  ExclamationCircleFilled,
  InfoCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Content, Header } from 'antd/es/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import styles from './Navigation.module.scss';
import { ReactComponent as logoSvg } from '../../assets/icons/sempra-logo.svg';
import { ReactComponent as dashboardSvg } from '../../assets/icons/dashboard.svg';
import { ReactComponent as handSvg } from '../../assets/icons/hand.svg';
import { ReactComponent as branchSvg } from '../../assets/icons/branch.svg';
import { ReactComponent as logoutSvg } from '../../assets/icons/logout.svg';
import Color from '../../types/enums/Colors';
import SummaryToggle from '../SummaryToggle/SummaryToggle';

const CLEAR_COOKIES_GUIDE_URL =
  'https://dev.azure.com/SempraUtilities/SempraUtilities/_wiki/wikis/SempraUtilities.wiki/10951/How-to-clear-Cookies-and-site-data';

// navigation menu items
const MENU_ITEMS_INFO = [
  {
    path: '/',
    icon: <Icon component={dashboardSvg} />,
    label: 'SEER Dashboard',
  },
  {
    path: '/insights/ticket-insights',
    icon: <Icon component={branchSvg} />,
    label: 'Ticket Insights',
  },
  {
    path: '/interaction-sentiment',
    icon: <Icon component={handSvg} />,
    label: 'Interaction Sentiment',
  },
  // Temporary solution - will be removed later after token refresh is implemented (User story #643284)
  {
    path: CLEAR_COOKIES_GUIDE_URL,
    icon: (
      <InfoCircleOutlined style={{ color: Color.BgNeutral, fontSize: 18 }} />
    ),
    label: 'Click here if the data is not loading',
    target: '_blank',
  },
  {
    path: '/login',
    icon: <Icon component={logoutSvg} />,
    label: 'Logout',
  },
];

const MENU_ITEMS = MENU_ITEMS_INFO.map((item, index) => ({
  key: `${index}`,
  label:
    item.path !== '/login' ? (
      <Link to={item.path} target={item.target}>
        {item.label}
      </Link>
    ) : (
      <span>{item.label}</span>
    ),
  icon: item.icon,
}));

function Navigation() {
  const [pageTitle, setPageTitle] = useState('');
  const [selectedItemKey, setSelectedItemKey] = useState('');
  const [isOnDashboard, setIsOnDashboard] = useState(false);
  const [isOnCallDetails, setIsOnCallDetails] = useState(false);
  const [isOnInsightsPage, setIsOnInsightsPage] = useState(false);
  const [activeTab, setActiveTab] = useState('ticket');

  const [{ confirm }, confirmContextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = theme.useToken();

  const insightsTabsOptions = [
    { label: 'Tickets View', value: 'ticket' },
    { label: 'Surveys View', value: 'survey' },
  ];

  useEffect(() => {
    // check current url path and set selected nav menu item accordingly (`selectedItemKey` and `pageTitle`)
    const index = MENU_ITEMS_INFO.findIndex(
      (item) => item.path === location.pathname
    );

    if (index !== -1) {
      const item = MENU_ITEMS_INFO[index];
      setSelectedItemKey(`${index}`);
      setPageTitle(item.label);
    }

    // Dashboard is on base path - used to display summary toggle
    if (location.pathname === '/') {
      setIsOnDashboard(true);
    } else {
      setIsOnDashboard(false);
    }

    // Insights is on base path - used to display tickets/surveys toggle
    if (/^\/insights\/.*$/.test(location.pathname)) {
      if (location.pathname.includes('ticket')) {
        setPageTitle(`Ticket Insights`);
        setActiveTab('ticket');
      } else {
        setPageTitle(`Survey Insights`);
        setActiveTab('survey');
      }
      setIsOnInsightsPage(true);
    } else {
      setIsOnInsightsPage(false);
    }

    // Call Details is on Interaction sentiment path with parameter - used to display back button
    if (/^\/interaction-sentiment\/.*$/.test(location.pathname)) {
      setIsOnCallDetails(true);
      // Chat ID always starts with "INC"
      if (location.pathname.includes('/INC')) {
        setPageTitle('Chat Details');
      } else {
        setPageTitle('Call Details');
      }
    } else {
      setIsOnCallDetails(false);
    }
  }, [location]);

  const onLogoutClick = useCallback(() => {
    // display confirmation modal on Logout button click
    confirm({
      title: 'Do you want to log out?',
      icon: <ExclamationCircleFilled />,
      content: 'This action will log you out of the application.',
      onOk() {
        navigate('/login');
      },
    });
  }, [confirm]);

  const changeActiveInsightsTab = (event: RadioChangeEvent) => {
    const path = event.target.value;
    setActiveTab(path);
    navigate(`/insights/${path}-insights`);
  };

  return (
    <Layout>
      {confirmContextHolder}

      {/* Navbar */}
      <Sider collapsed className={styles.navbar}>
        <div className={styles.content}>
          {/* Logo */}
          <Icon className={styles.sdgeLogo} component={logoSvg} />

          {/* Menu items */}
          <Menu
            className={styles.menu}
            theme="dark"
            defaultSelectedKeys={['0']}
            selectedKeys={[selectedItemKey]}
            items={MENU_ITEMS}
            onClick={(info) => {
              // if last menu item clicked, then user clicked on logout button
              if (info.key === `${MENU_ITEMS.length - 1}`) {
                onLogoutClick();
              }
            }}
          />
        </div>
      </Sider>

      {/* Page */}
      <Layout className={styles.page}>
        <Header
          className={styles.header}
          style={{ background: token.colorBgBase }}
        >
          <Row gutter={16} align="bottom">
            {/* Show back button only on Call Details page */}
            {isOnCallDetails && (
              <Col className={styles.backButton}>
                <Link to="/interaction-sentiment">
                  <Button shape="circle" icon={<LeftOutlined />} />
                </Link>
              </Col>
            )}

            <Col>
              <Typography.Title
                className={
                  pageTitle.includes('Insights')
                    ? styles.insightsTitle
                    : styles.pageTitle
                }
              >
                {pageTitle}
              </Typography.Title>
            </Col>

            {/* Show tickets/surveys toggle options only if we are on Insights */}
            {isOnInsightsPage && (
              <>
                <Col className={styles.dividerWrapper}>
                  <Divider type="vertical" />
                </Col>
                <Col className={styles.insightsToggle}>
                  <Radio.Group
                    options={insightsTabsOptions}
                    defaultValue="ticket"
                    onChange={changeActiveInsightsTab}
                    value={activeTab}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Col>
              </>
            )}

            {/* Show toggle options only if we are on Dashboard */}
            {isOnDashboard && (
              <>
                <Col className={styles.dividerWrapper}>
                  <Divider type="vertical" />
                </Col>
                <Col className={styles.daysToggleWrapper}>
                  <SummaryToggle />
                </Col>
              </>
            )}
          </Row>
        </Header>
        <Content className={styles.content} style={{ color: Color.Text }}>
          {/* Outlet will take care of picking up and displaying the component from the Router - /src/pages are being displayed in here */}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Navigation;
