import {
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  BarControllerChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { Bar } from 'react-chartjs-2';
import { BAR_CHART_COLORS } from '../../constants/visuals';

interface BarChartProps {
  labels: Array<string>;
  data: any;
  chartColors: Array<string>;
  selected?: string;
  onBarClick?: (dataIndex: number) => void;
  options?: any;
}

const selectedColor = BAR_CHART_COLORS.secondary[2];

function BarChart({
  labels,
  data,
  chartColors,
  selected,
  onBarClick,
  options,
}: BarChartProps) {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Ticket Volumes',
        data: labels.map((label) => data[label].count),
        backgroundColor: labels.map((label, index) =>
          label === selected
            ? selectedColor
            : chartColors[index % chartColors.length]
        ),
      },
    ],
  };

  const barOptions: _DeepPartialObject<
    CoreChartOptions<'bar'> &
      ElementChartOptions<'bar'> &
      PluginChartOptions<'bar'> &
      DatasetChartOptions<'bar'> &
      ScaleChartOptions<'bar'> &
      BarControllerChartOptions
  > = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: labels.map((label) =>
            label === selected ? selectedColor : 'white'
          ),
        },
        title: options?.scales?.x?.title || {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
        title: options?.scales?.y?.title || {
          display: false,
        },
      },
    },
    onClick: (_, elements) => {
      if (elements[0] && onBarClick) {
        const dataIndex = elements[0].index;
        onBarClick(dataIndex);
      }
    },
  };

  return <Bar options={barOptions} data={chartData} />;
}

export default BarChart;
