import { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './TrendsByChannelChart.module.scss';
import Box from '../Box/Box';
import LineChart from '../LineChart/LineChart';
import { useDashboardContext } from '../../context/Dashboard.context';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import HelpPopover from '../HelpPopover/HelpPopover';
import Img from '../Img/Img';
import VisualName from '../../types/enums/Visuals';
import ChartFilters from '../ChartFilters/ChartFilters';

function TrendsByContactTypeChart() {
  const {
    getVisualByName,
    visualStates: { ticketTrendsByChannel },
  } = useDashboardContext();

  const [totalTicketCount, setTotalTicketCount] = useState(0);
  const [currentTicketCount, setCurrentTicketCount] = useState(0);
  const [currentDateRange, setCurrentDateRange] = useState({
    start: '',
    end: '',
  });

  useEffect(() => {
    if (ticketTrendsByChannel.data) {
      const totalCount = ticketTrendsByChannel.data.total.reduce(
        (sum, item) => sum + parseInt(item.count, 10),
        0
      );
      const currentCount = ticketTrendsByChannel.data.current.reduce(
        (sum, item) => sum + parseInt(item.count, 10),
        0
      );
      setTotalTicketCount(totalCount);
      setCurrentTicketCount(currentCount);
      setCurrentDateRange(ticketTrendsByChannel.data.currentDateRange);
    }
  }, [ticketTrendsByChannel]);

  return !ticketTrendsByChannel.isLoaded ? (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
    </div>
  ) : (
    <Row gutter={[8, 8]} className={styles.graphWrapper}>
      <Col span={8} className={styles.flexColumn}>
        <div style={{ paddingBottom: '8px' }}>
          <Typography.Text className={styles.title}>
            Ticket volumes
          </Typography.Text>
        </div>
        <div>
          <Box type="secondary" className={styles.flexBoxDouble}>
            <div className={styles.subTitle}>
              <Typography.Text>Overall tickets</Typography.Text>
              <Typography.Text>Total count: {totalTicketCount}</Typography.Text>
            </div>

            {ticketTrendsByChannel.data?.total.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <DoughnutChart
                totalCount={totalTicketCount}
                graphData={ticketTrendsByChannel.data?.total}
              />
            )}
          </Box>

          <Box type="secondary" className={styles.flexBoxDouble}>
            <div className={styles.subTitle}>
              <Typography.Text>
                Tickets from last period
                <br />
                <span style={{ fontSize: '0.7em' }}>
                  ({currentDateRange.start} - {currentDateRange.end})
                </span>
              </Typography.Text>
              <Typography.Text>
                Total count: {currentTicketCount}
              </Typography.Text>
            </div>
            {ticketTrendsByChannel.data?.current.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data to visualize"
              />
            ) : (
              <DoughnutChart
                totalCount={currentTicketCount}
                graphData={ticketTrendsByChannel.data?.current}
              />
            )}
          </Box>
        </div>
      </Col>
      <Col md={24} lg={16}>
        <div className={styles.titleWithIcon}>
          <Row style={{ paddingBottom: 8 }}>
            <Typography.Text className={styles.title}>
              Bi-weekly trends
            </Typography.Text>
          </Row>
          <Row gutter={16} align="middle" style={{ paddingBottom: 8 }}>
            <Col>
              <ChartFilters
                value={{
                  dataSource: ticketTrendsByChannel.dataSource,
                  timeframe: ticketTrendsByChannel.timeframe,
                  timeframeType: ticketTrendsByChannel.timeframeType,
                }}
                options={[
                  { label: 'Incident', value: 'incidents' },
                  { label: 'Request', value: 'requests' },
                  { label: 'Survey', value: 'surveys' },
                ]}
                onChange={(value) => {
                  getVisualByName(
                    VisualName.TicketTrendsByChannel,
                    null,
                    value
                  );
                }}
              />
            </Col>

            <Col>
              <HelpPopover placement="bottomRight">
                <Img
                  src="./images/help/bi-weekly-ticket-volumes.jpeg"
                  alt="Description of Bi-weekly ticket volumes chart"
                  style={{
                    color: 'black',
                    width: '90vw',
                    maxWidth: '1000px',
                  }}
                />
              </HelpPopover>
            </Col>
          </Row>
        </div>
        <Box type="secondary" className={styles.flexBox}>
          {ticketTrendsByChannel.data?.timeline.data.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data to visualize"
            />
          ) : (
            <LineChart
              lineChartData={{
                ...ticketTrendsByChannel.data?.timeline,
                ticks: ticketTrendsByChannel.data?.timeline?.ticks || [],
                datasetLabels:
                  ticketTrendsByChannel.data?.timeline?.datasetLabels || [],
                data: ticketTrendsByChannel.data?.timeline?.data || [],
              }}
              yAxisLabel="Average daily tickets over bi-weekly period"
            />
          )}
        </Box>
      </Col>
    </Row>
  );
}

export default TrendsByContactTypeChart;
