import { Row, Col } from 'antd';

import styles from './CallTimeline.module.scss';
import { InteractionItemType, TimelineItem } from '../../types/Calls';
import { msToTime } from '../../utils/date-utils';
import Colors from '../../types/enums/Colors';
import Sentiment from '../Sentiment/Sentiment';

interface CallTimelineProps {
  timelineItems: TimelineItem[];
  agentSentiment: string;
  callerSentiment: string;
  type: InteractionItemType;
}
function CallTimeline({
  timelineItems,
  agentSentiment,
  callerSentiment,
  type,
}: CallTimelineProps) {
  const fillTimeGaps = (items: TimelineItem[]) => {
    if (items.length === 0) {
      return items;
    }

    const filledTimeline: TimelineItem[] = [];

    items.forEach((item, index) => {
      if (type === 'chat') {
        filledTimeline.push(item);
      } else {
        if (index === 0) {
          filledTimeline.push({
            BeginOffsetMillis: 0,
            EndOffsetMillis: item.BeginOffsetMillis,
            Sentiment: 'NONE',
            ParticipantId: 'NONE',
          });
        } else if (index < items.length) {
          filledTimeline.push({
            BeginOffsetMillis: items[index - 1].EndOffsetMillis,
            EndOffsetMillis: item.BeginOffsetMillis,
            Sentiment: 'NONE',
            ParticipantId: 'NONE',
          });
        }
        filledTimeline.push(item);
      }
    });
    return filledTimeline;
  };

  const getTimelineElement = (
    key: string,
    start: number,
    end: number,
    visible: boolean,
    source?: string
  ) => {
    let width;
    if (type === 'chat') {
      const startTime = timelineItems.at(1)?.Timestamp;
      const endTime = timelineItems.at(-1)?.Timestamp;
      width = `${(endTime! - startTime!) / timelineItems.length}%`;
    } else if (type === 'call') {
      const totalLength = timelineItems.at(-1)?.EndOffsetMillis || 0;
      width = `${((end - start) / totalLength) * 100}%`;
    }

    const style = {
      height: '10px',
      width,
      backgroundColor: '',
    };

    if (!visible) {
      style.backgroundColor = '';
    } else {
      switch (source) {
        case 'CUSTOMER':
          style.backgroundColor = Colors.BgNeutral;
          break;
        case 'AGENT':
          style.backgroundColor = Colors.Primary;
          break;
        default:
      }
    }

    return <div key={key} style={style} className={styles.timeDiv} />;
  };

  const getTimestamps = (items: TimelineItem[]) => {
    let totalLength: number;
    if (type === 'chat') {
      const startTime = timelineItems.at(1)?.Timestamp;
      const endTime = timelineItems.at(-1)?.Timestamp;
      if (startTime && endTime) {
        totalLength = endTime - startTime;
      }
    } else {
      totalLength = items.at(-1)?.EndOffsetMillis || 0;
    }
    return [...Array(11).keys()].map((index) => {
      const timestamp = msToTime((index * totalLength) / 10, false);
      return <span key={`timestamp-${index}`}>{timestamp}</span>;
    });
  };

  const getLegendItem = (label: string, color: string) => (
    <div className={styles.legend}>
      <div style={{ backgroundColor: color }} className={styles.legendCircle} />
      {label}
    </div>
  );

  const fullTimeline = fillTimeGaps(timelineItems);

  return (
    <div className={styles.wrapper}>
      <Row gutter={8}>
        <Col span={2} className={styles.labelCol}>
          Positive
        </Col>
        <Col span={21} className={styles.timeCol}>
          {fullTimeline.map((item, index) =>
            getTimelineElement(
              `positive-${index}`,
              item.BeginOffsetMillis,
              item.EndOffsetMillis,
              item.Sentiment === 'POSITIVE',
              item.ParticipantId
            )
          )}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={2} className={styles.labelCol}>
          Neutral
        </Col>
        <Col span={21} className={styles.timeCol}>
          {fullTimeline.map((item, index) =>
            getTimelineElement(
              `positive-${index}`,
              item.BeginOffsetMillis,
              item.EndOffsetMillis,
              item.Sentiment === 'NEUTRAL',
              item.ParticipantId
            )
          )}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={2} className={styles.labelCol}>
          Negative
        </Col>
        <Col span={21} className={`${styles.timeCol} ${styles.lastCol}`}>
          {fullTimeline.map((item, index) =>
            getTimelineElement(
              `positive-${index}`,
              item.BeginOffsetMillis,
              item.EndOffsetMillis,
              item.Sentiment === 'NEGATIVE',
              item.ParticipantId
            )
          )}
        </Col>
      </Row>

      <Row>
        <Col span={2} className={styles.labelCol} />
        <Col span={21} className={styles.timestamps}>
          {getTimestamps(fullTimeline)}
        </Col>
      </Row>
      <Row className={styles.legendWrapper}>
        <div>
          {getLegendItem('Agent', Colors.Primary)}
          {getLegendItem('Caller', Colors.BgNeutral)}
        </div>
        <Sentiment
          agentSentiment={agentSentiment}
          callerSentiment={callerSentiment}
        />
      </Row>
    </div>
  );
}

export default CallTimeline;
