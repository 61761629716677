import { Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login/Login';
import Dashboard from '../../pages/Dashboard/Dashboard';
import TicketInsights from '../../pages/TicketInsights/TicketInsights';
import SurveyInsights from '../../pages/SurveyInsights/SurveyInsights';
import InteractionSentiment from '../../pages/InteractionSentiment/InteractionSentiment';
import NotFound from '../../pages/NotFound/NotFound';
import Navigation from '../Navigation/Navigation';
import CallDetail from '../../pages/CallDetail/CallDetail';

function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Navigation />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/insights/ticket-insights" element={<TicketInsights />} />
        <Route path="/insights/survey-insights" element={<SurveyInsights />} />
        <Route
          path="/interaction-sentiment"
          element={<InteractionSentiment />}
        />
        <Route path="/interaction-sentiment/:id" element={<CallDetail />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default Router;
