import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants/formatting';

export const formatDate = (date: string | Date) =>
  dayjs(date).format(DATE_FORMAT);

export const formatDateTime = (date: string | Date) =>
  dayjs(date).format(DATE_TIME_FORMAT);

// convert miliseconds to hh:mm:ss
export const msToTime = (miliseconds: number, displayHours = true) => {
  // seconds
  const s = Math.floor((miliseconds / 1000) % 60);
  // minutes
  const m = Math.floor((miliseconds / (1000 * 60)) % 60);
  // hours
  const h = Math.floor((miliseconds / (1000 * 60 * 60)) % 24);

  const ss = s < 10 ? `0${s}` : s;
  const hh = h < 10 ? `0${h}` : h;
  let mm;
  if (displayHours) {
    mm = m < 10 ? `0${m}` : m;
  } else {
    mm = h * 60 + m;
    mm = mm < 10 ? `0${mm}` : mm;
  }

  return displayHours ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
};
