const debounce = (cb: (e: any) => void, time: number) => {
  let timeout: NodeJS.Timeout | null = null;

  return (e: React.ChangeEvent<HTMLInputElement> | string) => {
    const next = () => cb(e);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(next, time);
  };
};

// change to named export once we have more than 1 variable to export
export default debounce;
