import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { CallDetails, CallsPayload } from '../types/Calls';
import { CallsContext } from '../types/Context';
import callsService from '../services/calls.service';
import { CallFilter, CallFilterOptions } from '../types/Filters';

interface Props {
  children: ReactNode;
}

const Context = createContext<CallsContext>({} as CallsContext);

export const useCallsContext = () => useContext(Context);

export function CallsProvider({ children }: Props) {
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [calls, setCalls] = useState<CallDetails[]>([]);
  const [filter, setFilter] = useState<CallFilter>({
    dateRange: null,
    interactionType: null,
    callDuration: 'all',
    callTags: [],
    // agentName: null,
    // endingSentiment: null,
    agentSentiment: null,
    callerSentiment: null,
  });
  const [andOrSwitch, setAndOrSwitch] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<CallFilterOptions>({
    tags: [],
    agentList: [],
    callDurationRanges: [],
    sentiments: [],
    interactionTypes: [],
  });
  const [hasLoadedOptions, setHasLoadedOptions] = useState(false);

  const getCalls = async (payload: Partial<CallsPayload>) => {
    setCalls([]);
    const response = await callsService.getCalls({
      ...payload,
      ...filter,
      andOrSwitch,
    });
    setCalls(response.calls);
    setTotalCount(response.totalCount);
    return response;
  };

  const getFilterOptions = async () => {
    const options = await callsService.getCallFilterOptions();
    setFilterOptions(options);
    setHasLoadedOptions(true);
    return options;
  };

  const contextValue = useMemo<CallsContext>(
    () => ({
      totalCount,
      pageNumber,
      setPageNumber,
      calls,
      getCalls,
      filter,
      setFilter,
      andOrSwitch,
      setAndOrSwitch,
      filterOptions,
      getFilterOptions,
      hasLoadedOptions,
    }),
    [
      totalCount,
      pageNumber,
      setPageNumber,
      calls,
      getCalls,
      filter,
      setFilter,
      andOrSwitch,
      setAndOrSwitch,
      filterOptions,
      getFilterOptions,
      hasLoadedOptions,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
