import { ReactComponent as ArrowSVG } from '../../assets/icons/arrow.svg';
import styles from './OverallSentimentTrend.module.scss';

interface OverallSentimentTrendProps {
  sentimentTrend: string;
}

function OverallSentimentTrend({ sentimentTrend }: OverallSentimentTrendProps) {
  switch (sentimentTrend) {
    case 'POSITIVE':
      return <ArrowSVG className={styles.positive} />;
    case 'NEGATIVE':
      return <ArrowSVG className={styles.negative} />;
    default:
      return null;
  }
}

export default OverallSentimentTrend;
